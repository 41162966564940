const faTranslation = {
  Dashboard: "پیشخوان",
  auth: {
    kyc: {
      PendingForApproval:
        "اطلاعات شما توسط کارشناس در حال بررسی می باشد، بعد از تایید یا تغییر وضعیت اطلاع رسانی خواهد شد.",
      IdentityCardNotConfirmed: "",
    },
    termConditionFirst: "با ثبت‌نام در افیلیو٬",
    termConditionSecond: "را می‌پذیرم",
    pleaseEnterFirst: "لطفا کد ارسال شده به شماره زیر را وارد کنید",
    loginDescription: "نام کاربری خود را وارد کنید",
    recoveryDescription: "نام کاربری خود را وارد کنید",
    errorLoginMessage: "نام کاربری یا رمز عبور اشتباه است",
    errorInLogin: "خطایی پیش آمده",
    selectTypeUser: "انتخاب نوع همکاری",
    MERCHANT: "مرچنت: میخواهم محصولاتم را تبلیغ کنم.",
    PUBLISHER: "ناشر: فضای تبلیغاتی دارم.",
    LEGAL: "حقوقی",
    PRIVATE: "حقیقی",
    phone: "شماره موبایل",
    usernameLogin: "شماره موبایل",
    password: "رمز عبور",
    passwordConfirm: "تکرار رمز عبور",
    passwordNew: "رمز عبور جدید",
    passwordConfirmNew: "تکرار رمز عبور جدید",
    showAgreement: "قوانین و مقررات",
    passwordNotMatch: "عدم تطابق رمزهای عبور",
    helpPassword: "حداقل ۸ حرف(حروف بزرگ و کوچک، عدد و نماد)",
    register: "ثبت نام",
    forgot: "فراموشی رمز عبور",
    login: "ورود",
    contractTitle: "قوانین و مقررات",
    contractText: `سامانه همکاری در فروش (که در این قرارداد به اختصار سامانه همکاری نامیده می‌شود.)، سامانه ای است که بر روی وب سایت دیجی کالا به آدرس www.Digikala.com ایجاد می‌شود و شامل نرم افزار، اپلیکیشن، سرویس ها، داده ها و طراحی های موجود در آن می‌باشد.
      عضو شدن در آن به شما امکان می دهد که از وب سایت ، شبکه اجتماعی، و نرم افزارهای آنلاین خود (که در این شرایط و قوانین به اختصار "وبسایت" نامیده می شوند .( درآمدزایی بنمایید. اشخاصی که در این سامانه عضو شده و نام کاربری و رمز عبور دریافت نمایند، همکار/ همکاران نامیده می شوند.
      دیجی کالا در سامانه همکاری در فروش، یک لینک اختصاصی که برای هر فرد به صورت مجزا تعریف می‌شود، در اختیارتان قرار می دهد و لینک مذکور باید به طور کاملا صحیح و از فرمت لینک تگ شده ی مخصوصی که دیجی کالا تهیه نموده و مطابق این شرایط و قوانین تنظیم شده است، باشد.
      این لینک در واقع کالاهای پیشنهادی دیجی کالا را معرفی می نماید که مطابق با موضوع فعالیت "وبسایت" شما، کالاهای مرتبط را در بر می گیرد.
      شما با قراردادن لینک اختصاصی خود در "وب سایت" تان، می توانید درآمد زایی کنید.`,
    verify: "تایید شماره همراه",
    successRegisterMessage: "ثبت نام با موفقیت انجام شد.",
    successLoginMessage: "در حال انتقال ...",
    rememberMe: "مرا به خاطر بسپار",
    loginText: "معامله ای کاملا دوسر برد و موفق",
    registerText: "پلتفرم جامع همکاری در فروش",
    agree: "موافقم",
    accept: "تایید",
    changePassword: "تغییر رمز عبور",
    enterNewPassword: "رمز عبور جدید را وارد کنید:",
    passUpdatedSuccessFully: "رمز عبور با موفقیت تغییر یافت.",
    shouldAcceptAgreement: "قبول قوانین الزامی است.",
    contract_alert: `جهت ادامه کار با حساب کاربری از بخش پروفایل کاربری، قرارداد خود را مطالعه و تایید نمایید.`,
    username: "نام کاربری",
    sendCode: "ارسال کد تایید",
    sendCodeDescription: "لطفا کد ارسال شده به ایمیل زیر را وارد کنید",
    mobileVerify: "تایید موبایل",
    mobileDescription:
      "موبایل خود را تایید و یا در صورت نیاز آن را ویرایش و سپس تایید کنید",
    sendmobileCodeDescription: "لطفا کد ارسال شده به موبایل زیر را وارد کنید",
    changeMobileNumber: "تغییر شماره موبایل",
    resetPassword: "تعیین رمز عبور جدید",
    textOldLogin: "اگر قبلا کاربر افیلیو بوده اید،",
    clickHere: "کلیک کنید",
    back: "بازگشت",
    yourAccountRecovered: "حساب شما",
    recoverySuccessFullyDone:
      "حساب کاربری شما قبلا بازیابی شده است. لطفا از صفحه ورود وارد حساب کاربری خود شوید.",
    emailError: "فیلد ایمیل اجباری است",
    phoneError: "فیلد شماره موبایل اجباری است",
    mer_intro:
      "می‌توانید با استفاده از پتانسیل رسانه های ناشران افیلیو محصولات خود را به فروش برسانید.",
    pub_intro:
      "می‌توانید با استفاده از ترافیک رسانه خود (سایت، شبکه های اجتماعی)، مشتریان بسیاری برای فروشگاه ها جذب کرده و کسب درآمد کنید.",
    ADVERTISER: "ناشر - مالک رسانه",
  },
  wizard: {
    legalPerson: "کاربر حقوقی",
    privatePerson: "شخص حقیقی",
    vatTitle: "گواهی ارزش افزوده",
    vat_included: "مشمول مالیات بر ارزش‌افزوده هستم",
    vat_not_included: "مشمول مالیات بر ارزش‌افزوده نیستم",
    stepPersonal: "اطلاعات شخصی",
    stepCompany: "اطلاعات شرکت",
    stepContact: "اطلاعات تماس",
    stepPayment: "اطلاعات پرداخت",
    stepTax: "مالیات بر ارزش افزوده",
    complete: "در انتظار تایید",
    securityConfig: "تنظیمات امنیتی",
    contract: "قرارداد",
    serviceSetting: "تنظیمات سرویس",
    publisherEntryDescription:
      "شما به عنوان آگهی دهنده(ناشر) وارد پنل شده اید٬ نوع کاربری خود را مشخص کرده و سپس فرم زیر را تکمیل نمایید",
    merchantEntryDescription:
      "شما به عنوان فروشنده(مرچنت) وارد پنل شده اید٬ نوع کاربری خود را مشخص کرده و سپس فرم زیر را تکمیل نمایید",
    yourInfoSuccessfullySubmit:
      "با تشکر از همکاری شما، اطلاعات شما با موفقیت ثبت شد و در وضعیت بررسی اطلاعات قرارگرفت. منتظر اطلاع رسانی های بعدی  باشید.",
  },
  suggest: {
    code: "کد محصول",
    image: "عکس محصول",
    name: "نام محصول",
    category: "دسته بندی",
    commission: "کمیسیون",
    addLink: "ایجاد لینک",
    priority: "الویت",
    title: "عنوان",
    property: "ویژگی",
  },
  user: {
    changePassword: "تغییر رمز عبور",
    legal: "نوع کاربر",
    firstName: "نام",
    lastName: "نام خانوادگی",
    fatherName: "نام پدر",
    gender: "جنسیت",
    birthday: "تاریخ تولد",
    nationalCode: "کد ملی",
    identityNumber: "شماره شناسنامه",
    picNationalCode: "تصویر کارت ملی",
    picIdentityNumber: "تصویر شناسنامه",
    FEMALE: "خانم",
    MALE: "آقا",
    notValidNationalCode: "کد ملی نامعتبر",
    notValidIdentNumber: "شماره شناسنامه نامعتبر",
    mobile: "شماره موبایل",
    phoneNumber: "تلفن ثابت",
    phoneNumberNotValid: "تلفن نامعتبر",
    email: "ایمیل",
    province: "استان",
    city: "شهر",
    postalCode: "کد پستی",
    address: "آدرس",
    addressPlaceHolder: "خیابان اصلی، خیابان فرعی، کوچه، پلاک",
    notValidShebaCode: "شبا نامعتبر",
    sheba: "شماره شبا",
    picVatDocument: "تصویر گواهی ارزش افزوده",
    picPaperDocument: "تصویر روزنامه رسمی",
    taxOnVatText:
      "گواهی ارزش افزوده اشخاص حقیقی باید به نام شخص ثبت کننده اطلاعات باشد.",
    companyName: "نام شرکت",
    registerNumber: "شماره ثبت",
    companyType: "نوع شرکت",
    nationalId: "شناسه ملی شرکت",
    economicalNumber: "کد اقتصادی شرکت",
    firstNameLegal: "نام نماینده شرکت",
    lastNameLegal: "نام خانوادگی نماینده شرکت",
    company: {
      PUBLIC: "سهامی عام",
      PRIVATE: "سهامی خاص",
      LIMITED: "مسئولیت محدود",
    },
    plaque: "پلاک",
    MERCHANT: "فروشنده",
    PUBLISHER: "ناشر",
    AFFILIO: "افیلیـو",
    SUPER_ADMIN: "مدیر سیستم",
    ADMIN: "مدیر",
    SERVICE: "خدمات",
    errorAllKycMustBeApproved: "خطا، همه مدارک کاربر تایید نشده!",
    roleAssigned: "نقش های تخصیص یافته",
    privileges: "دسترسی های خاص",
    addAdmin: "ایجاد ادمین",
    types: {
      PUBLISHER: "ناشر",
      MERCHANT: "فروشنده",
      SUPER_ADMIN: "سوپر ادمین",
      ADMIN: "ادمین",
      SERVICE: "خدمات",
    },
    oldUserId: "شناسه قبلی",
    oldUsername: "نام کاربری قبلی",
    id: "شناسه کاربر",
  },
  input: {
    click_or_drag_file: "آپلود فایل",
    support_single: "حداکثر 5MB، با فرمت JPG، PNG",
    support_single_mix: "حداکثر 10 با فرمت JPG، PNG یا PDF",
    support_single_PDF: "حداکثر 10MB، با فرمت PDF",
    support_single_pdf: "حداکثر 10MB، با فرمت PDF",
    support_single_csv: "حداکثر 10MB، با فرمت CSV یا XLSX",
    uploadError: "خطا، در آپلود فایل",
  },
  general: {
    okConfirm: "بله",
    saveSuccessFully: "با موفقیت ثبت شد",
    successFullyDone: "با موفقیت انجام شد",
    copySuccessFully: "کپی شد!",
    changes_title: "لیست بروزرسانی ها",
    unHandleErrorMessage: "خطای غیر منتظره، لطفا مجددا تلاش کنید.",
    select: "انتخاب کنید",
    goBack: "بازگشت به صفحه قبل",
    submitFilter: "اعمال فیلتر",
    exportExcel: "دانلود خروجی اکسل",
    emailNotValid: "نامعتبر",
    pleaseFillAllInputs: "لطفا، همه فیلدها را تکمیل کنید !",
    notValidNumber: "شماره نامعتبر",
    submitContinue: "ثبت و ادامه",
    submitAndGetCode: "ثبت و دریافت کد",
    back: "بازگشت",
    submitEnd: "ثبت نهایی",
    titleSite: "افیلیو",
    titleSiteSSP: "ادورج |‌ پلت فرم مدیریت فضای تبلیغات",
    sureForRegenerate: "آیا فاکتور مجددا صادر شود؟",
    sureForeRetry: "آیا فایل گزارش مجددا ایجاد شود؟",
    logout: "خروج",
    academia: "DOCS",
    submit: "ثبت اطلاعات",
    submit_: "ثبـت",
    submitEdit: "ویرایش",
    cancel: "لغو",
    sureDelete: "آیا برای حذف مطمئن هستید؟",
    searchAdvanced: "جستجوی پیشرفته",
    save: "ثبـت",
    percent: "درصد",
    close: "بستن",
    return: "بازگشت",
    wizardMode: "نمایش در فرم ویزارد",
    wizardModeHelp: "در صورتی که کاربر حداقل یکبار تایید نشده باشد",
    ok: "بله",
    ok2: "برورزسانی",
    no: "خیر",
    end: "پایان",
    active: "فعال",
    deActive: "غیرفعال",
    delete: "حذف",
    print: "پرینت",
    UnexpectedErrorOccurred: "خطا، لطفا مجددا تلاش کنید.",
    error400: "متاسفانه صفحه مورد نظر شما یافت نشد!",
    ValidationError: "خطای اعتبارسنجی، لطفا مقادیر وارد شده را کنترل نمایید.",
    urlFormatNotValid: "آدرس رسانه نامعتبر است ",
    saveAndContinue: "ثبت و ادامه",
    SureCancel: "انصراف از تغییرات؟",
    pleaseWait: "در حال بارگذاری ...",
    pleaseWaitMessage2: "لطفا منتظر بمانید ...",
    pleaseWaitMessage: "درحال اعمال تغییرات ...",
    sureForDelete: "این ایتم حذف شود؟",
    sureForClone: "از این آیتم یک کپی ایجاد شود؟",
    sureForChange: "آیا برای تغییر ",
    selectAll: "همه",
    currency: "تومان",
    from: "از",
    to: "تا",
    Dollar: "دلار",
    Euro: "یورو",
    Rial: "ریال",
    Toman: "تومان",
    undo: "انصراف از تغییرات",
    accept: "تایید",
    reject: "رد",
    edit: "ویرایش",
    view: "نمایش",
    inputSearch: "کلیدواژه مورد نظر را جستجو کنید ...",
    IdAndinputSearch: "کلیدواژه یا شناسه مورد نظر را جستجو کنید ...",
    selectPublisher: " ناشر",
    download: "دانلود",
    totalNumber: "تعداد نتایج",
    total: "کل",
    noData: "ندارد",
    new_release_message_title: "نسخه جدید",
    new_release_message:
      "نسخه جدیدی از پنل هم اکنون آماده شده است، لطفا بروزرسانی نمایید.",
    networkError: "خطای شبکه، لطفا دوباره تلاش کنید!",
    networkErrorAccess: "خطا، لطفاً از اتصال اینترنت خود مطمئن شوید!",
    networkErrorNeedAuth: "خطا، لطفا مجددا وارد حساب کاربری خود شوید!",
    sureLeavePage: "در صورت بستن فرم، اطلاعات وارد شده پاک میشود.",
    understood: "متوجه شدم!",
    check: "انتخاب",
    rowSelected: "ردیف انتخاب شده است:",
    saveChanges: "ذخیره تغییرات",
    farsiNotAllowed: "فقط حروف انگلیسی مجاز است",
    persian: "فارسی",
    english: "انگلیسی",
    confirmBatchInvoice:
      "در صورت تغییر وضعیت فاکتورها به <b>تایید شده</b>، همه پرداخت‌ها به <b>آماده پرداخت</b> تغییر وضعیت پیدا می‌کنند.",
    confirmSingleInvoice: `در صورت تغییر وضعیت فاکتور به 
        <b>status</b> 
        وضعیت پرداخت به 
        <b>payment</b>
        تغییر خواهد کرد.
        `,
    helpTour: "راهنمای استفاده",
    registerComplete: "تکمیل ثبت نام",
    chatTitle: "پشتیبانی افیلیو",
    chatTitleOffline: "پشتیبانی آفلاین افیلیو",
    retry: "ساخت مجدد!",
    copy: "کپی کردن کد",
    copyLink: "کپی لینک",
    copied: "کپی شد",
    error_in_receive_file: "خطای دریافت فایل",
    unavailable: "ناموجود",
    width: "عرض",
    height: "ارتفاع",
    ignore: "فعلا نه",
    continue: "ادامه",
    upload: "آپلــود",
    welcomeMobileText: "خوش آمدید",
    welcomeAdminText: "به پنل ادمین افیلیو خوش آمدید",
    welcomeNonAdminText: "به پنل کاربری افیلیو خوش آمدید",
    dateFrom: "از تاریخ",
    dateTo: "تا تاریخ",
  },
  profile: {
    rankUser: "امتیاز",
    userState: "وضعیت ثبت نام",
    contractState: "وضعیت قرارداد",
    confirmed: "تایید شده",
    rejected: "رد شده",
    unknown: "",
    requiredUpdate: "مدرک تایید نشده، لطفا مجددا آپلود نمایید",
    showOldUser: "اطلاعات پنل قبلی کاربر",
    user: {
      Registering: "درحال ثبت نام",
      PendingForApproval: "در انتظار بررسی",
      Confirmed: "تایید",
      InReview: "در حال بررسی",
      Rejected: "رد شده",
      Blocked: "بلاک شده",
      KysError: "خطای مدارک",
    },
    contract: {
      NEW: "در انتظار تایید",
      APPROVED: "تایید شده",
      REJECTED: "رد شده",
      EXPIRED: "منقضی شده",
      BLOCKED: "فسخ شده",
    },
    verify: {
      Verified: "تایید",
      UnVerify: "رد",
      Pending: "منتظر تایید",
      Failed: "داری مشکل",
    },
    accessManagement: "مدیریت دسترسی ها",
    saveUrl: "ثبت",
    testApi: "تست API",
    enterUrl: "www.api.exmaple.com",
    serviceSettingText: "آدرس API Call Back سفارش",
    dataModel: "دیتا مدل:",
    responseTitle: "پاسخ درخواست",
  },
  page: {
    "aff-suggest": "پیشنهاد افیلیو",
    productSuggest: "محصولات پیشنهادی",
    categoriesSuggest: "دسته بندی‌ پیشنهادی",
    Profile: "پروفایل کاربری",
    Kits: "کیت",
    Tickets: "تیکت ها",
    Analytics: "آمار و گزارشات",
    Websites: "فروشگاه ها",
    Dashboard: "پیشخوان",
    Users: "مدیریت کاربران",
    users: "کاربران",
    contracts: "قراردادها",
    Publishers: "مدیریت ناشران",
    UserDetail: "اطلاعات کاربر",
    Merchants: "مدیریت فروشندگان",
    ContractTemplates: "مدیریت قالب قراردادها",
    ContractTempDetail: "جزئیات قالب",
    ContractsUserList: "قراردادها",
    ContractDetail: "جزئیات قرارداد",
    ContractTempDetailAdd: "افزودن قالب قرارداد",
    SubWebsiteAdd: "افزودن فروشگاه زیرمجموعه",
    SubWebsiteEdit: "ویرایش فروشگاه زیرمجموعه",
    WebsiteAdd: "افزودن فروشگاه",
    WebsiteEdit: "ویرایش فروشگاه",
    websites: "فروشگاه ها",
    websitesSSP: "وب سایت ها",
    WebsiteCommission: "کمیسیون ها",
    WebsiteCommissionHistory: "تغییرات کمیسیون",
    Medias: "رسانه ها",
    MediaEdit: "ویرایش رسانه",
    MediaDetails: " جزییات رسانه",
    Settings: "تنظیمات",
    SettingAccount: "تنظیمات حساب کاربری",
    Reasons: "مدیریت پاسخهای آماده",
    Subjects: "مدیریت زمینه های فعایت",
    Setting: "تنظیمات حساب کاربری",
    Links: "لینک ها",
    EditLink: "ویرایش لینک",
    Banners: "بنرهای فروشنده",
    BannersMerchant: "بنرها",
    BannerNew: "ایجاد بنر",
    BannerEdit: "ویرایش بنر",
    Widgets: "ویجت ها",
    WidgetsMerchants: "ویجتـهای فروشندگان",
    WidgetsPublishers: "ویجتـهای ناشران",
    websitesManage: "مدیریت فروشگاه ها",
    WidgetNew: "ایجاد ویجت",
    WidgetEdit: "ویرایش ویجت",
    WidgetManagement: "مدیریت ویجت محصولات",
    WebsiteCommissionList: "درخواستهای تغییر کمیسیون",
    BannersWidget: "بنرهای ناشران",
    BannersWidgetPublisher: "بنرها",
    BannersWidgetTitle: "بنرهای ایجاد شده",
    BannerWidget: "جزئیات بنر",
    BannerWidgetNew: "ایجاد بنر",
    BannerWidgetEdit: "ویرایش بنر ویجت",
    Orders: "سفارشات",
    Invoices: "مدیریت مالی",
    InvoicesPage: "فاکتورها",
    InvoicesMerchant: "فاکتورهای فروشندگان",
    InvoicesPublisher: "فاکتورهای ناشران",
    Roles: "مدیریت نقش ها",
    InvoiceDetail: "جزئیات فاکتور",
    Projections: "برنامه ریزی پرداخت",
    FinanceMerchant: "مالی فروشندگان",
    FinancePublisher: "مالی ناشران",
    LinksManage: "مدیریت لینکها",
    Reports: "گزارشات",
    ReportsOrder: "فایلهای گزارشات",
    Support: "پشتیبانی",
    PayoutsPage: "پرداخت ها",
    Administrators: "مدیریت ادمین‌ها",
    Administrator: "پروفایل ادمین",
    SmartWidgets: "ویجت های محصولات",
    register: "ثبت نام",
    forgot: "فراموشی رمز عبور",
    recovery: "بازیابی پروفایل",
    Groups: "تگهای ویجت",
    SmartWidget: "اسمارت ویجت",
    SmartWidgetNew: "ایجاد اسمارت ویجت",
    SmartWidgetEdit: "ویرایش اسمارت ویجت",
    Login: "ورود",
    Register: "ثبت نام",
    Recovery: "بازیابی پروفایل",
    ForgotPassword: "فراموشی رمز عبور",
    ResetPassword: "تعیین رمز عبور جدید",
    Campaigns: "کمپین ها",
    Campaign: "ایجاد کمپین",
    FAQ: "سوالات متداول",
    faqSearch: "جستجو در سوالات متداول",
    ReportsMedia: "گزارش عملکرد رسانه ها",
    FlowsList: "لیست FLOW ها",
    FormulasList: "لیست فرمول ها",
    FormulaAdd: "افزودن فرمول",
    FormulaDetail: "ویرایش فرمول",
    FormulaEdit: "ویرایش فرمول",
    FlowAdd: "افزودن FLOW",
    FlowEdit: "ویرایش FLOW",
    InsightReport: "گزارش عملکرد افیلیو",
    AddNewWidget: "ایجاد ویجت",
    SelectNewWidget: "انتخاب ویجت",
    EditWidget: "ویرایش ویجت",
    AdPlaces: "جایگاه های تبلیغاتی",
    MyWebsites: "وبسایت های من",
    PlaceNew: "افزودن جایگاه جدید",
    PlaceEdit: "ویرایش جایگاه تبلیغاتی",
    AddContractTitle: "افزودن قرارداد",
    EditContractTitle: "ویرایش قرارداد",
    TicketCreate: "ایجاد تیکت",
    TicketEdit: "پاسخ تیکت",
    place_analytics: "گزارش عملکرد جایگاه",
  },
  admins: {
    Id: "شناسه",
    undefined: "نامشخص",
    Actions: "جزئیات",
    autoLogin: "ورود خودکار",
    Role: "نقش",
    Mobile: "موبایل",
    Email: "ایمیل",
    Status: "وضعیت",
    Name: "نام و نام‌ خانوادگی",
    sureToSetActive: "برای فعال سازی، مطمئن هستید؟",
    sureToUnsetActive: "برای غیرفعال سازی، مطمئن هستید؟",
    gender: "جنسیت",
    successFullyDone: "با موفقیت انجام شد",
    UnexpectedErrorOccurred: "خطا، لطفا مجددا تلاش کنید.",
    detail: "جزییات",
    saveSuccessFully: "با موفقیت ثبت شد",
    unHandleErrorMessage: "خطای غیر منتظره، لطفا مجددا تلاش کنید.",
    firstName: "نام",
    lastName: "نام خانوادگی",
    phone: "شماره موبایل",
    submit: "ثبت",
    FEMALE: "آقا",
    MALE: "خانم",
    addAdmin: "افزودن مدیر",
  },
  users: {
    Id: "شناسه",
    NameOrCompany: "نام/نام شرکت",
    UserType: "نوع شخص",
    LEGAL: "حقوقی",
    PRIVATE: "حقیقی",
    undefined: "نامشخص",
    DateRegister: "تاریخ ثبت نام",
    StatusKyc: "وضعیت مدارک",
    StatusContract: "وضعیت قرارداد",
    ChangeLegalStatus: "تغییر نقش کاربر",
    UserStatus: "وضعیت ثبت نام",
    VerifyStatus: "وضعیت حساب",
    actions: "جزئیات",
    detail: "جزئیات",
    history: "تاریخچه",
    autoLogin: "ورود خودکار",
    inputSearch: "کلیدواژه مورد نظر را جستجو کنید ...",
    role: "نقش",
    StatusDocumentUser: "ارزیابی مدارک کاربر",
    StatusUserForm: "تغییر وضعیت کاربر",
    statusDescription: "توضیحات",
    approved: "تایید شده",
    error: "تایید نشده",
    sureForAutoLogin: "مطمئن جهت ورود به حساب کاربر؟",
    status: {
      waiting: "در انتظار بررسی",
      approved: "تایید شده",
      rejected: "رد شده",
    },
    Mobile: "موبایل",
    Email: "ایمیل",
    Status: "وضعیت",
    Name: "نام و نام‌ خانوادگی",
    close: "بستن",
    phoneNumberPrefix: "کد شهر",
  },
  kycDoc: {
    // PendingForFirstApproval: "ویزارد",
    PersonalInfoConfirmed: "اطلاعات شخصی",
    CompanyInfoConfirmed: "اطلاعات شرکت",
    BankAccountInfoConfirmed: "اطلاعات بانکی",
    ContactInfoConfirmed: "اطلاعات تماس",
    NationalCardConfirmed: "کارت ملی",
    IdentityCardConfirmed: "شناسنامه",
    VatDocumentConfirmed: "گواهی ارزش افزوده",
    NewsPaperDocumentConfirmed: "روزنامه رسمی",
    kysDocApprove: "لطفا، موارد مورد تایید را انتخاب کنید:",
    kysDescription: "توضیح خطای مدارک",
  },
  contract_tmp: {
    id: "شناسه قالب",
    name: "عنوان قالب",
    type: "نوع قالب",
    is_enabled: "وضعیت",
    create_date: "تاریخ ایجاد",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    actions: "",
    PrivatePublisher: "ناشر حقیقی",
    LegalPublisher: "ناشر حقوقی",
    Merchant: "فروشنده",
    detail: "جزئیات",
    Waiting: "در حال انتظار",
    Active: "فعال",
    Approved: "تایید شده",
    Expired: "منقضی",
    Rejected: "رد شده",
    Blocked: "بلاک",
    Draft: "پیش نویس",
    confirm: "نوع ویرایش قالب",
    create_new_templateYes: "قالب جدیدی ایجاد شود.",
    create_new_templateNo: "قالب فعلی ویرایش شود.",
    variables: "متغیر های قابل استفاده",
    user: "کاربر",
  },
  contract: {
    number: "قرارداد شماره",
    status: "وضعیت قرارداد",
    create_date: "تاریخ ایجاد قرارداد",
    approve_date: "تاریخ تایید قرارداد",
    reject_date: "تاریخ فسخ قرارداد",
    download: "دریافت قرارداد",
    approve: "قبول دارم",
    reject: "قبول ندارم",
    history: "آرشیو قراردادها",
    id: "شناسه",
    name: "عنوان قرارداد",
    is_enabled: "فعال؟",
    is_enabled_: "وضعیت کلی",
    create_date_: "تاریخ ایجاد",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    last_modify_date: "تاریخ آخرین بروزرسانی",
    trueState: "فعال",
    falseState: "غیرفعال",
    paper: "فایل قرارداد",
    savePaper: "ذخیره فایل",
    numberRegister: "شماره ثبت",
    date: "تاریخ ایجاد",
    smartech: "شرکت اطلس ارتباط رامان",
    uploadFileLegal:
      "بعد از مطالعه قرارداد، فایل قرارداد را دانلود کنید و بعد از امضا آن را آپلود نمایید.",
    contract_type: "نوع قرارداد",
    paper_contract_status: "قرارداد فیزیکی؟",
    from_start_date: "تاریخ شروع از",
    to_start_date: "تاریخ شروع تا",
    from_approve_date: "تاریخ تایید از",
    to_approve_date: "تاریخ تایید تا",
    from_end_date: "تاریخ پایان از",
    to_end_date: "تاریخ پایان تا",
    from_upload_paper_date: "تاریخ اپلود از",
    to_upload_paper_date: "تاریخ اپلود تا",
    upload_paper_date: "تاریخ آپلود",
    user_id: "شناسه کاربر",
    ok: "دارد",
    no: "ندارد",
    update: "بروزرسانی قرارداد",
  },
  website: {
    id: "شناسه",
    merchant: "فروشنده",
    name: "نام فروشگاه",
    englishName: "نام انگلیسی فروشگاه",
    url: "آدرس فروشگاه",
    status: "وضعیت",
    create_date: "تاریخ ایجاد",
    actions: "",
    selectParent: "فروشگاه اصلی",
    add: "افزودن فروشگاه",
    subject: "زمینه فعالیت",
    detail: "مشخصات فروشگاه",
    levels: "سطوح فروشگاه",
    defaultLevel: "پیش فرض",
    imageUpload: "لوگو فروشگاه",
    tradeName: "نام تجاری فروشگاه",
    about: "درباره فروشگاه",
    levelName: "عنوان سطح",
    addLevel: "افزودن سطح",
    encrypted_id: "شناسه API فروشگاه",
    addSuccessfullySubmit: "اطلاعات فروشگاه با موفقیت ثبت شد.",
    categories: "دسته بندیها",
    amount: "مبلغ",
    new_customer_price: "مبلغ سفارش مشتری جدید",
    new_customer_registration_price: "مبلغ ثبت نام مشتری جدید",
    currency: "ارز",
    discount: "تخفیف",
    is_enabled: "وضعیت",
    Dollar: "دلار",
    Euro: "یورو",
    Rial: "ریال",
    Toman: "تومان",
    newCurrency: "کمیسیون کاربر جدید",
    Edit: "ویرایش",
    active: "فعال",
    noActive: "غیرفعال",
    is_sub_store: "زیرمجموعه؟",
    sub_web_store_name: "عنوان",
    save_changes: "ذخیره تغییرات",
    sub_web_store_name_en: "عنوان انگلیسی",
    sureToEnableWebsite: "آیا از فعال سازی فروشگاه مطمئن هستید؟",
    sureToDisableWebsite: "آیا از غیرفعال سازی فروشگاه مطمئن هستید؟",
    cookie_expire: "کوکی",
    cookie_time_type: "کوکی",
    cookie: {
      Day: "روز",
      Minute: "دقیقه",
      Hour: "ساعت",
      Month: "ماه",
      Year: "سال",
    },
    relation_type_: {
      FULL: "FULL",
      ONLY: "ONLY",
      EXCEPT: "EXCEPT",
    },
    commissions: "کمیسیون ها",
    commissionLink: "ویرایش کمیسیون ها",
    alertForEdit: `جهت ویرایش کمیسیون های دسته بندی ها
       به صفحه کمیسیون های محصولات مراجعه نمایید.`,
    save: "ذخیره",
    delete: "حذف",
    errorSingleLevel: "تنها یک سطح پیش فرض قابل انتخاب است.",
    paymentConfig: "تنظیمات محاسبه کمیسیون",
    productsCommissions: "کمیسیون محصولات",
    levels_: {
      Leaf: "Leaf",
      Closest: "Closest",
      Root: "Root",
      Custom: "Custom",
    },
    close: "بستن",
    add_sub_web_store: "افزودن زیرمجموعه",
    product_name: "نام محصول",
    commission_normal: "کمیسیون عادی",
    commission_promotion: "کمیسیون پروموشن",
    relation_type: "نوع ارتباط",
    relation_type_select: "انتخاب نوع ارتباط",
  },
  faq: {
    pageTitle: "در چه زمینه‌ای نیاز به راهنمایی دارید؟",
    profile: "حساب کاربری",
    medias: "رسانه‌ها",
    links: "لینک‌ها",
    orders: "سفارشات",
    financials: "امور مالی",
    faq: "سوالات متداول",
    search: "نتایج جستجو",
    noData: "برای کلیدواژه مورد نظر نتیجه‌ای یافت نشد.",
    back: "بازگشت",
    orderDetails: "وضعیت سفارشات",
    orderDescription:
      "وضعیت سفارشات انجام شده از طریق قسمت سفارشات قابل مشاهده است. سفارشات در یکی از وضعیت های زیر قرار دارند:",
    financeFlow: "فرآیند مالی",
    financeDescription:
      "در تاریخ های مشخص شده جهت ساخت فاکتور، تمامی سفارشات قطعی شده در فاکتور بر اساس فرمول های موجود محاسبه شده و در یک فاکتور در اختیار ناشر قرار می‌گیرد. فاکتورها از طریق فرایند شناسایی تقلب بررسی شده و دارای یکی از وضعیت های رد شده، تایید شده و مشکوک خواهند بود. لازم به ذکر است فقط فاکتورهای تایید شده در سر‌رسید پرداخت به واحد مالی جهت پرداخت ارجاع می‌شود. فاکتورهای رد شده قابلیت پرداخت ندارند.",
  },
  commission: {
    CategoryName: "دسته بندی",
    Commission: "کمیسیون عادی",
    Commission_: "کمیسیون عادی",
    PromotionCommission: "کمیسیون پروموشن",
    PromotionCommission_: "کمیسیون پروموشن",
    Action: "ویرایش",
    importCsv: "وارد کردن اکسل",
    exportCsv: "خروجی اکسل",
    csvPlaceholder: "",
    download: "دانلود فایل اکسل",
    uploadFile: " کمیسیون ها",
    selectLevel: " سطح",
    active: "فعال سازی",
    selectWebsite: " وب سایت",
    commissionFormatError: "محتوای فایل ارسالی صحیح نیست.",
    saveChanges: "ذخیره تغییرات",
    commissionsChanged: "کمیسیون را تغییر داده اید",
    importMessage:
      "ابتدا فایل اکسل کمیسیون محصولات را دانلود کرده، بعد از اعمال تغییرات فایل ویرایش شده را آپلود کنید.",
    status: {
      defaultMessage: "وضعیت تغییرات اعمال شده در سطح",
      defaultMessageAdmin: "آیا تغییرات اعمال شده را تایید می کنید؟",
      PendingForApprove: "در انتظار بررسی",
      Active: "فعال",
      Approved: "تایید شده",
      Rejected: "رد شده",
      Closed: "بسته شده",
      Ended: "پایان یافته",
    },
    pleaseFill: "لطفا فیلدها را پر کنید",
    categorySelected: "دسته بندی انتخاب شده است",
    titleApprove: "تاریخ اعمال کمیسیون",
    selectApplyDate: "لطفا تاریخ اعمال تغییرات کمیسیون را انتخاب کنید:",
    showAll: "نمایش همه",
    currentCom: "کمیسیون های جاری",
    newCom: "کمیسیون های جدید",
    titleReject: "دلیل رجکت تغییرات کمیسیون",
    justEditedCommissions: "فقط کمیسیون های ویرایش شده",
    history: "تاریخچه ویرایشها",
    label: "#",
    dateFrom: "از تاریخ",
    dateTo: "تا تاریخ",
    detail: "",
    id: "شناسه",
    name: "عنوان",
    status_: "وضعیت",
    apply_date: "تاریخ بروزرسانی",
    last_modify_date: "تاریخ بروزرسانی",
    approve_date: "تاریخ تایید",
    reject_date: "تاریخ رد",
    create_date: "تاریخ ایجاد",
    show: "نمایش",
    view: "نمایش",
    level: "سطح کمیسیون",
    rejectTitle: "دلیل رد درخواست",
    moreInfo: "اطلاعات بیشتر",
    dateReject: "تاریخ رد درخواست",
    helpTextEdit:
      "بدلیل متفاوت بودن سطح محاسباتی پیش فرض فروشگاه، امکان ویرایش این کمیسیون وجود ندارد.",
    helpCommissionCalculateTitle: "راهنمای نحوه محاسبه کمیسیون",
    AffilioCommission: "کمیسیون افیلیو%",
  },
  media: {
    sureForDeleteMedia: "آیا از حذف رسانه مطمئن هستید؟",
    id: "شناسه",
    name: "نام رسانه",
    type: "نوع رسانه",
    selectMedia: " رسانه",
    delete: "حذف",
    subject: "زمینه فعالیت",
    create_date: "تاریخ ایجاد",
    test_mode: "نحوه همکاری",
    content_status: "تایید محتوا",
    ownerShip_status: "احراز مالکیت",
    actions: "ویرایش",
    add: "افزودن رسانه",
    Website: "وب سایت",
    Instagram: "اینستاگرام",
    Telegram: "تلگرام",
    LinkedIn: "لیندکین",
    WhatsApp: "واتس اپ",
    Facebook: "فیس بوک",
    YouTube: "یوتیوب",
    WeChat: "وی چت",
    Messenger: "مسنجر",
    TelegramBot: "بات تلگرام",
    TikTok: "تیک تاک",
    Aparat: "آپارات",
    Pinterest: "پینترست",
    url: "آدرس رسانه",
    quickEdit: "ویرایش سریع",
    edit: "ویرایش",
    selectWebsite: "فروشگاه",
    test_mode_op: {
      PendingForApprove: "درحال بررسی",
      InTestMode: "آزمایشی",
      ActiveUser: "رسمی",
    },
    content_status_op: {
      New: "در انتظار بررسی",
      Approved: "تایید شده",
      Rejected: "رد شده",
      Block: "مسدود",
    },
    ownerShip_status_op: {
      New: "در انتظار بررسی",
      Approved: "تایید شده",
      Rejected: "رد شده",
    },
    info: "اطلاعات رسانه",
    websites: "فروشگاه ها",
    last_modify_date: "تاریخ آخرین ویرایش",
    publisher_name: "مالک رسانه",
    website: "فروشگاه",
    websiteLevel: "سطح فروشگاه",
    active: "فعال",
    de_active: "غیرفعال",
    addWebsite: "افزودن فروشگاه",
    descriptionReject: "توضیحات",
    description: "توضیحات",
    editStatus: "ویرایش وضعیت",
    is_enabled: "وضعیت",
    editTestMode: "ویرایش وضعیت نحوه همکاری",
    editOwnershipStatus: "ویرایش وضعیت احراز مالکیت",
    editContentStatus: "ویرایش وضعیت تایید محتوا",
    ownershipVerification: "احراز مالکیت",
    websiteEmail: "ایمیل وبسایت",
    verifyDescription:
      "احراز مالکیت وبسایت خود را با وارد کردن ایمیل وبسایت تایید کنید.",
    verifyInfo:
      "ایمیل وب‌سایت ایمیلی است که بعد از @ نام وب‌سایت شما نمایش داده می‌شود.",
    sendCode: "ارسال کد تایید",
    codeDescription: "لطفا کد ارسال شده به ایمیل زیر را وارد کنید:",
    editEmail: "ویرایش ایمیل",
    details: "جزییات",
    alertMessage:
      "وبسایت‌های شما احراز مالکیت نشده اند، لطفا روی دکمه احراز مالکیت کلیک کنید.",
    show: "(مشاهده)",
    website_name: "نام وب سایت",
    domain: "دامنه",
    preText: `
      اطلاعات وب‌سایت خود را وارد کنید و مطمئن شوید که محتوای سایت شما مطابق با
      `,
    ruleUsText: " قوانین و مقررات ",
    preText_append: "می باشد.",
    waitingOtpMessage:
      "تا لحظاتی دیگر یک کد تایید به ایمیل‌تان ارسال خواهد شد. لطفا کد تایید را در فیلد زیر وارد کنید.",
    success_verifyTitle: "احراز مالکیت با موفقیت انجام شد!",
    success_verifyText: `به زودی محتوای سایت شما توسط ادمین مورد بررسی قرار خواهد گرفت و نتیجه از طریق ایمیل به شما اطلاع داده می‌شود.`,
    understand: "متوجه شدم",
    whyIsDisabled: "چرا رسانه شما غیرفعال است؟",
    textConditionsRequireForActivate:
      "شرط فعال شدن وب‌سایت شما، تایید احراز مالکیت و تایید محتوا است. غیر فعال بودن وب‌سایت شما به دلایل زیر می‌باشد :",
    content_status_text: `محتوای سایت شما توسط ادمین در حال بررسی است و هنوز تایید نشده است. نتیجه بررسی از طریق ایمیل به شما اطلاع داده می‌شود.`,
    content_status_text_Rejected: `محتوای سایت شما دارای واژه ها و عکس هایی مغایر با اخلاق و قوانین جمهوری اسلامی ایران می‌باشد. شما می‌توانید پس از اصلاح محتوا، درخواست بررسی مجدد بدهید.`,
    ownerShip_status_text: `شما هنوز احراز مالکیت وب‌سایتتان را انجام نداده اید.`,
    sureForDelete: "این وب سایت حذف شود؟",
    editWebsite: "ویرایش وب سایت",
  },
  reason: {
    add: "افزودن پاسخ آماده",
    reasonText: "یک دلیل تعریف کنید",
    reason: "متن پاسخ",
    id: "شناسه",
    type: "نوع پاسخ",
    edit: "ویرایش",
    create_date: "تاریخ ایجاد",
    last_modify_date: "تاریخ اپدیت",
    is_enabled: "وضعیت",
    select: "دلیل رجکت",
    types: {
      Media: "رسانه",
      Contract: "قرارداد",
      UserDocument: "سند کاربر",
      Commission: "کمیسیون",
      Payout: "پرداخت",
    },
    status: {
      true: "فعال",
      false: "غیرفعال",
    },
    // reason: "دلیل رجکت",
  },
  subject: {
    id: "شناسه",
    name: "عنوان",
    namePlaceholder: "عنوان زمینه فعالیت",
    create_date: "تاریخ ایجاد",
    is_enabled: "وضعیت",
    edit: "ویرایش زمینه فعالیت",
    add: "افزودن زمینه فعالیت",
    // types: {
    //   EmailSubject: "موضوع ایمیل",
    //   EmailBody: "متن ایمیل",
    //   RegisterSmsText: "پیامک تایید ثبت نام",
    //   PublisherTermsAndConditions: "شرایط پابلیشر",
    //   MerchantTermsAndConditions: "شرایط فروشنده",
    // },
  },
  setting: {
    account: "حساب کاربری",
    security: "تغییر رمز عبور",
    mobile: "شماره همراه",
    role: "نقش کاربر",
    created_date: "تاریخ ایجاد",
    hi: "سلام، ",
  },
  link: {
    sure_for_active: "آیا از فعال سازی لینک مطمئن هستید؟",
    sure_for_deactive_title: "آیا از غیرفعال کردن لینک مطمئن هستید؟",
    sure_for_deActive:
      "در صورت غیرفعال سازی، لینک شما مسدود خواهد شد؛ بدین معنی که وقتی کاربر روی آن کلیک ‌کند، با صفحه خطا  مواجه می شود؛ اما در صورت نیاز هر زمان که بخواهید امکان فعالسازی مجدد لینک را خواهید داشت.",
    sureforDelete:
      "در صورت حذف، لینک شما مسدود خواهد شد؛ بدین معنی که وقتی کاربر روی آن کلیک ‌کند، با صفحه خطا مواجه می شود. همچنین امکان فعالسازی مجدد این لینک وجود ندارد و در صورت نیاز، مجددا باید لینک جدید بسازید. ",
    sureForDeleteTitle: "آیا از حذف لینک مطمئن هستید؟",
    stepInfo: "اطلاعات لینک",
    stepResult: "لینکهای ساخته شده",
    id: "شناسه",
    name: "عنوان",
    website: "فروشگاه",
    media: "رسانه",
    type: "نوع لینک",
    clicks_daily: "کلیک های امروز",
    clicks_total: "کل کلیک ها",
    created_date: "تاریخ ایجاد",
    is_enabled: "وضعیت",
    address: "آدرس اصلی",
    address_short: "لینک افیلیت",
    add: "ایجاد لینک",
    howCreateLink: "لطفا روش تولید لینک را انتخاب کنید:",
    linkType: {
      Private: "لینک اختصاصی",
      Public: "لینک عمومی",
      BannerWidget: "ویجت بنر",
      ProductWidget: "ویجت محصول",
      Promotion: "پروموشن",
    },
    selectWebsite: " فروشگاه",
    selectMedia: " رسانه",
    title: "عنوان لینک",
    addressLink: "آدرس صفحه مورد نظر",
    helperAddress: "آدرس صفحه ای که میخواهید کاربران به آن هدایت شوند",
    editLink: "ویرایش لینک",
    createLink: "ایجاد لینک",
    copyLink: "کپی لینک",
    mainLink: "لینک اصلی",
    shortLink: "لینک افیلیت",
    // affilioLink: "آدرس افیلیو",
    subjects: "زمینه های فعالیت",
    edit: "ویرایش لینک",
    remove: "حذف لینک",
    group_id: "تگ",
    status: "وضعیت",
    selectType: " نوع لینک",
    previewLink: "مشاهده لینک",
    privateLink: "لینک اختصاصی",
    publicLink: "لینک عمومی",
    privateLinkGuide:
      "در تولید لینک اختصاصی، همانطور که از نام آن مشخص است، کاربر دقیقا به همان صفحه ای که آدرس آن را در فرم زیر وارد کرده اید، هدایت می شود. ",
    publicLinkGuide:
      "در لینک‌ عمومی، نیازی به ساخت لینک‌های جداگانه برای هر صفحه نیست. فقط کافی است یک بار لینک عمومی را دریافت کنید و سپس لینک هر صفحه‌ای که می‌خواهید را به صورت نسخه Base64 به انتهای آن اضافه کنید.",
    guideb64:
      "کد base64 آدرس مورد نظر را به جای {YOUR_BASE64_ENCODED_URL} قرار دهید.",
    insertLinkInYourMedia:
      "لینک های افیلیت زیر را کپی کرده و در رسانه خود قرار دهید:",
    destination_type: "نوع مقصد",
    d_type: {
      Other: "پیش فرض",
      WebStoreLandingPage: "صفحه PLP فروشگاه",
      ProductPage: "صفحه محصول",
      SearchPage: "صفحه جستجو",
      CampaignPage: "صفحه کمپین",
    },
    active: "فعال",
    deActive: "غیرفعال",
  },
  banner: {
    id: "شناسه",
    name: "عنوان بنر",
    title: "عنوان بنر",
    website: "فروشگاه",
    today_click_count: "کلیک های روز",
    total_click_count: "کل کلیک ها",
    created_date: "تاریخ ایجاد",
    is_enabled: "وضعیت",
    generateCode: "تولید کد",
    add: "ایجاد بنر",
    actions: "",
    subject: "دسته بندی",
    description: "توضیحات",
    address: "آدرس بنر",
    addImage: "افزودن تصویر",
    size: "سایز تصویر",
    image: "تصویر بنر",
    width: "عرض",
    height: "ارتفاع",
    info: "اطلاعات بنر",
    media: "رسانه",
    is_promotion: "پروموشن",
    is_default: "پیش فرض",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    images: "تصاویر بنر",
    tbl: {
      Remove: "حذف",
      Dimensions: "ابعاد بنر",
      LastUpdate: "آخرین آپدیت",
      ShowImage: "نمایش بنر",
      Add: "افزودن",
    },
    sureToSetDefault: "برای انتخاب این بنر، به عنوان پیش فرض مطمئن هستید؟",
    create_date: "تاریخ ایجاد",
    last_modify_date: "تاریخ بروزرسانی",
    code: "دریافت کد",
    detail: "جزئیات",
    create_widget: "ایجاد بنر",
    sureToActive: "آیا برای فعال کردن بنر مطمئن هستید؟",
    sureToDeActive: "آیا برای غیرفعال کردن بنر مطمئن هستید؟",
    promotion_item: {
      yes: "بله",
      no: "خیر",
    },
    status: "وضعیت",
    error: {
      dimensionError: "ابعاد تصویر انتخاب شده صحیح نیست.",
    },
    conditionHelp:
      "قابلیت پیش فرض شدن یک بنر،‌ تنها در صورت آپلود همه ابعاد آن بنر امکان پذیر است.",
    sureToUnsetDefault: "بنر پیشفرض غیرفعال شود؟",
  },
  bannerWidget: {
    // selectedBanner: "بنر انتخاب شده",
    add: "ایجاد بنر",
    copyCode: "کپی کد",
    selectSize: "انتخاب سایز",
    subject: "موضوع",
    end: "پایان",
    dimension: "ابعاد",
    default: "پیش فرض",
    previewImage: "پیش نمایش",
    createWidget: "تولید کد",
    sureToSetActive: "برای فعال سازی، مطمئن هستید؟",
    sureToUnsetActive: " آیا از  غیرفعال کردن بنر مطمئن هستید؟",
    footerBanner:
      "بنر پیش فرض بنری است که هر زمان فروشگاه مورد نظر، بنر فعلی را غیرفعال کند، بنر پیش فرض نمایش داده میشود.",
    resultCreate:
      "ویجت یا ویجت های مورد نظر با موفقیت ایجاد شد. اکنون می توانید از قسمت زیر کد آنها را کپی کنید:",
  },
  widget: {
    category: "دسته بندی ویجتها",
    priceFilter: "فیلتر براساس قیمت",
    filtered: "فیلترهای اعمال شده",
    name: "عنوان ویجت",
    type: "نوع ویجت",
    number: "ویجت شماره",
    add: "افزودن ویجت",
    edit: "ویرایش ویجت",
    pleaseSelect: "لطفا روش تولید ویجت را انتخاب نمایید:",
    generatedCode: "کد تولید شده",
    copyLink: "کپی کد",
    listMyWidget: "لیست ویجتهای من",
    preview: "پیش نمایش",
    pre: "برگشت",
    copied: "کپی شد!",
    selectWebsite: "انتخاب فروشگاه",
    website: "فروشگاه",
    today_click_count: "کلیک های روز",
    total_click_count: "کل کلیک ها",
    create_date: "تاریخ ایجاد",
    edit_date: "تاریخ ویرایش",
    generate_code: "تولید کد",
    Prev: "قبلی",
    Next: "بعدی",
    results: "ویجتها",
    media: "نام رسانه",
    noData:
      "شما هنوز ویجتی ثبت نکرده‌اید. برای ثبت ویجت٬ دکمه زیر را انتخاب کنید",
    noDataPublisher:
      "درحال حاضر برای فروشگاه انتخاب شده، اسمارت ویجتی وجود ندارد، لطفا فروشگاه دیگری را انتخاب نمایید.",
    selectWidgetType: "انتخاب نوع ویجت",
    customWidget: "ویجت معمولی",
    customWidgetDescription: "تولید ویجت از محصولات فروشگاه ها به صورت دلخواه",
    smartWidget: "اسمارت ویجت",
    smartWidgetDescription:
      "انتخاب ویجت آماده از میان ویجت های پیشهادی فروشگاه ها",
    suggested: "پیشنهادی",
    stepInfo: "اطلاعات ویجت",
    resultWidgets: "ویجت های تولید شده",
    getCode: "دریافت کد",
    alertSelectWebsite: "برای ایجاد ویجت، یک فروشگاه انتخاب کنید",
    sureChange: `امکان تولید ویجت از چندین فروشگاه مختلف وجود ندارد،
         در صورتی که فروشگاه دیگری را انتخاب نمایید، 
         محصولات قبلی انتخاب شده حذف خواهند شد.
          در صورت اطمینان بر روی تایید کلیک نمایید.`,
    selectGroup: "برچسب ویجت",
    type_: {
      Smart: "اسمارت",
      Manual: "معمولی",
    },
    countProduct: "تعداد محصول",
    createdDate: "تاریخ ایجاد",
    tags: "دسته بندی ها",
    campaign: "کمپین",
    show_campaign_detail: "مشاهده کمپین",
    specTitle: "مشخصات ویجت",
    telegramBot: "تلگرام بات",
    status: "وضعیت",
    active: "فعال",
    deActive: "غیرفعال",
    createWidget: "ایجاد ویجت",
    createTag: "افزودن تگ جدید",
    display_widget: "پیش نمایش ویجت",
  },
  product: {
    category: "دسته بندی ها",
    priceFilter: "فیلتر براساس قیمت",
    filtered: "فیلترهای اعمال شده",
    sort: {
      Ascending: "قدیمی ترین",
      Descending: "جدیدترین",
      MinPrice: "ارزانترین",
      MaxPrice: "گرانترین",
      MinToMaxSale: "کمترین تخفیف - بیشترین تخفیف",
      MostDiscount: "پر تخفیف ترین",
    },
    previewSelectedItems: "نمایش انتخاب شده ها",
    selectedItems: "آیتم های انتخاب شده",
    searchProduct: "جستجوی محصول ...",
    productIdPlaceHolder: "شناسه  (DKP-xxxxxx)",
    onlyExists: "فقط کالاهای موجود",
    search: "جستجو کنید ...",
    cheapest: "ارزانترین",
    expensive: "گرانترین",
    showProduct: "مشاهده محصول",
    selectedProduct: "محصول انتخاب شده",
    createWidget: "ایجاد ویجت",
    editWidget: "ویرایش ویجت",
    numberSelected: "دسته بندی انتخاب شده",
    productId: "شناسه محصول",
    buyFrom: "خرید از ",
    show_product: "نمایش محصول",
  },
  order: {
    bonus_amount: "مبلغ پاداش",
    idOrUniqueId: "شناسه یا شناسه یکتا",
    id: "شناسه یکتا",
    media: "رسانه",
    link: "لینک",
    payment_status: "وضعیت پرداخت",
    receive: "دریافت",
    publisher_name: "ناشر",
    website: "فروشگاه",
    create_date: "تاریخ ثبت سفارش",
    status_: "وضعیت سفارش",
    type: "نوع سفارش",
    discount: "تخفیف",
    total_payment: "مبلغ سفارش",
    new_customer: "مشتری جدید",
    new_customer_amount: "مبلغ مشتری جدید",
    status_order: "وضعیت سفارش",
    vat: "مالیات",
    original_order_id: "کد سفارش",
    original_order_id_system: "شناسه ",
    commission_amount: "مبلغ کمیسیون",
    mediaType: "نوع رسانه",
    create_date_from: "تاریخ ایجاد از",
    create_date_to: "تاریخ ایجاد تا",
    final_date_from: "تاریخ نهایی شدن از",
    final_date_to: "تاریخ نهایی شدن تا",
    pd: {
      id: "کد محصول",
      name: "نام محصول",
      count: "تعداد",
      category: "دسته بندی",
      commission_percent: "کمیسیون تعلق گرفته",
      commission_price: "مبلغ کمیسیون",
      price: "قیمت محصول",
      pdp_amount: "پاداش فروش مستقیم محصول",
      plp_amount: "پاداش فروش مستقیم دسته‌بندی",
    },
    status: {
      New: "جدید",
      MerchantApproved: "تایید فروشنده",
      Finalize: "تایید نهایی",
      Canceled: "کنسل",
      WaitingForInvoice: "فاکتور نشده",
      Invoiced: "فاکتور شده",
      Paid: "پرداخت شده",
      notSet: "نامشخص",
      MerchantRejected: "رد فروشنده",
    },
    filter_by_date_name: "تاریخ گزارش بر اساس",
    filter_by_date: {
      ORDER_DATE: "تاریخ ایجاد سفارش",
      FINALIZE_DATE: "تاریخ نهایی شدن سفارش",
      INVOICE_DATE: "تاریخ فاکتور شدن سفارش",
    },
    exportCsv: "خروجی اکسل",
    orders: "سفارشات",
    ordersDetail: "جزئیات سفارشات",
    exportCsvQuickText: `<b>Quick Report</b>:
       گزارشی از رکوردهای موجود در همین صفحه آماده سازی میکند.`,
    exportCsvFullText: `<b>Full Report</b>:
      خروجی کاملی از کل داده های موجود براساس فیلترهای انتخابی آماده سازی میکند.
      <br/>
       گزارش ها بعد از آماده سازی از قسمت فایلهای گزارشات قابل دریافت است.
      `,
    exportCsvForm: "ایجاد گزارش",
    pleaseSelectTypeExport: "لطفا نوع گزارش گیری خود را مشخص نمایید:",
    yourReportInProcess: `گزارش شما با شناسه
      <b>xxx</b> 
       درحال آماده سازی است.
      <br/>
      میتوانید وضعیت آن را از قسمت فایلهای گزارشات پیگیری نمایید:
      `,
    reportView: "مشاهده گزارش ها",
    sum_publisher_commission: "مبلغ کل کمیسیون ناشر",
    sum_merchant_commission: "مبلغ کل کمیسیون فروشنده",
    sum_affilio_commission: "مبلغ کل کمیسیون افیلیو",
    sum_nmv_price: "مبلغ کل سفارشات",
    sum_new_customer_count: "تعداد مشتریان جدید",
    sum_order_count: "تعداد سفارشات ",
    order_finalized_date: "تاریخ نهایی شدن سفارش",
  },
  invoice: {
    bonus_amount: "مبلغ پاداش",
    amount_without_commission: "مبلغ",
    id: "شناسه",
    contract: "قرارداد",
    id_search: "شناسه فاکتور",
    merchant_id: "شناسه فروشنده",
    web_store: "فروشگاه",
    merchant_name: "نام فروشنده",
    publisher_id: "شناسه ناشر",
    publisher_name: "نام ناشر",
    projection: "شناسه پروجکشن",
    start_date: "تاریخ شروع ",
    end_date: "تاریخ پایان ",
    create_date: "تاریخ ایجاد ",
    payment_date: "تاریخ پرداخت ",
    payment_date_start: "تاریخ پرداخت از",
    payment_date_end: "تاریخ پرداخت تا",
    create_date_start: "تاریخ ایجاد از",
    create_date_end: "تاریخ ایجاد تا ",
    status_invoice: "وضعیت فاکتور",
    status_payment: "وضعیت پرداخت",
    amount: "مبلغ فاکتور",
    amount_vat: "مالیات",
    commission: "کمیسیون",
    total_amount: "مبلغ نهایی",
    total: "مبلغ کل",
    detail: "جزئیات فاکتور",
    export_excel: "خروجی اکسل",
    order_detail_: "جزئیات",
    print: "پرینت فاکتور",
    eventId: "شناسه",
    event: "شرح",
    count: "تعداد",
    commission_amount: "مبلغ کمیسیون",
    discount_amount: "مبلغ تخفیف",
    actions: "",
    download: "دریافت فاکتور",
    website: "فروشگاه",
    status: "وضعیت",
    addBillLegal: "آپلود فاکتور رسمی",
    editBillLegal: "ویرایش فاکتور رسمی",
    status_: {
      Initial: "پیش نویس",
      Confirmed: "تایید شده",
      Rejected: "رد شده",
      Pending: "در حال بررسی",
    },
    pay_status_: {
      WaitingToProcess: "در انتظار پرداخت",
      Confirmed: "تایید شده",
      ReadyForPayment: "آماده پرداخت",
      TreasuryAcceptance: "تایید حواله",
      TreasuryRejected: "رد حواله",
      PaymentBlock: "پرداخت مسدود شده",
      Paid: "پرداخت شده",
    },
    type: {
      CostPerOrder: "کمیسیون سفارشات",
      CostPerOrderByDeductingVoucher: "کمیسیون سفارشات با کسر کوپن",
      NewCustomerRegistration: "ثبت نام کاربر جدید",
      NewCustomerOrder: "سفارش کاربر جدید",
      Bounty: "پاداش",
    },
    cal_du_to: "تاریخ محاسبات",
    setInvoiceStatus: "تغییر وضعیت فاکتور",
    setPaymentStatus: "تغییر وضعیت پرداخت فاکتور",
    description: "توضیحات",
    addBill: "افزودن رسید پرداخت",
    editBill: "ویرایش رسید پرداخت",
    editBill_: "رسید پرداخت",
    viewBill: "جزئیات",
    projection_id: "شناسه مالی",
    projection_id_title: "شناسه مالی ناشر",
    get_order_detail: "نمایش جزئیات سفارش",
    order_detail: "جزئیات سفارش",
    payment_due_date: "مهلت پرداخت",
    documents_count: "پیوستها",
  },
  finance: {
    invoices: "فاکتورها",
    projections: "برنامه ریزی پرداخت ها",
    settings: "تنظیمات پرداخت",
    payouts: "پرداخت ها",
    reportPublisherPayout: "گزارش پرداختی ناشران",
  },
  role: {
    titlePrivilege: "عنوان دسترسی",
    tag: "تگ",
    name: "نام نقش",
    edit: "ویرایش نقش",
    add: "افزودن نقش",
    search: "جستجوی نقش",
    privileges: "دسترسی ها",
    table: {
      titleAccess: "دسترسی ها",
      view: "نمایش",
      create: "ایجاد",
      edit: "ویرایش",
      delete: "حذف",
    },
    userType: "نوع نقش",
    userCount: "کاربر",
    showUsers: "نمایش کاربران",
    submitRole: "ایجاد نقش",
    editRole: "ویرایش نقش",
    type: "نوع نقش",
    allPrivileges: "همه دسترسی ها",
    assignPrivileges: "دسترسی های داده شده",
  },
  projection: {
    id: "شناسه",
    merchant: "فروشنده",
    publisher: "ناشر",
    bucket: "سطح کاربر",
    contract: "قرارداد",
    title: "عنوان",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    payment_date: "تاریخ پرداخت",
    invoice_date: "تاریخ صدور فاکتور",
    status: "وضعیت",
    active_status: "وضعیت فعال",
    invoice: "فاکتور",
    syncMessage: "در صورت مشاهده نتایج نادرست، کلیک کنید!",
    sync: "یکپارچه سازی داده ها",
    status_: {
      Initial: "جدید",
      WaitingToCalculate: "در انتظار محاسبه",
      WaitingToGenerate: "در انتظار ایجاد",
      Calculated: "محاسبه شده",
      CalculatedWithZeroResult: "محاسبه شده با نتیجه صفر",
    },
  },
  dashboard: {
    CPO: "CPO",
    AIV: "AIV",
    click: "کلیک",
    click_redirect: "کلیک",
    click_sdk: "کلیک SDK",
    view: "بازدید",
    order: "سفارش",
    revenue: "درآمـد",
    ctr: "CTR",
    impression: "نمایش",
    dashboard: "پیشخوان",
    filters: "فیلترها",
    orderTotal: "تعداد کل سفارشات",
    amountTotal: "مبلغ کل سفارشات",
    orderTotalCount: "تعداد سفارشات نهایی شده",
    cancelOrderCount: "تعداد سفارشات کنسل شده",
    submittedOrderCount: "تعداد کل سفارشات",
    topPublisherSubmittedOrderCount: "تعداد سفارشات ثبت‌شده ۱۰ناشر برتر",
    topPublisherFinalizedOrderCount: "تعداد سفارشات نهایی‌شده ۱۰ناشر برتر",
    pureOrderCount: "تعداد خالص سفارشات ",
    newCustomerOrderTotalCount: "تعداد مشتریان جدید سفارشات نهایی شده",
    newCustomerSubmittedOrderCount: "تعداد مشتریان جدید سفارشات ثبت شده",
    costAllOrder: "مبلغ سفارشات نهایی شده (NMV)",
    pureOrderAmount: "مبلغ خالص سفارشات",
    cancelOrderAmount: "مبلغ سفارشات کنسل شده",
    rateConvert: "نرخ تبدیل(کلیک به سفارش)",
    conversionRate: "نرخ‌های تبدیل",
    finalOrders: "تعداد سفارشات نهایی شده",
    clickToSubmittedOrder: "کلیک به سفارش",
    finalOrderToSubmittedOrder: "سفارشات به سفارش نهایی",
    newCustomerToSubmittedOrder: "سفارشات به مشتری جدید",
    newCustomerToFinalOrder: " سفارشات نهایی به مشتری جدید",
    commissionOverNmv: "کمیسیون به NMV",
    topTenPublishers: "۱۰ ناشر برتر",
    topCategories: "۱۰ دسته بندی برتر",
    topLinks: "۱۰ لینک برتر",
    topMedias: "۱۰ رسانه برتر",
    newPublishersStatistics: "آمار ناشران جدید",
    publishersAndLinksAndActiveMedias: "ناشران، لینک‌ها و رسانه‌های فعال",
    confirmedPublishers: "ناشران تایید شده",
    activePublishers: "ناشران فعال",
    pieDescription: "ناشران فعال به تفکیک نوع شخص",
    donutDescription: "به تفکیک نوع لینک",
    activeMediasCount: "تعداد رسانه‌های فعال",
    activeLinksCount: "تعداد لینک‌های فعال",
    privatePublisher: "ناشر حقیقی",
    legalPublisher: "ناشر حقوقی",
    generatedOrder: "کمیسیون سفارش ثبت شده",
    finaliezedOrder: "سفارش نهایی شده",
    ncByFinalized: "مشتری جدید به سفارش نهایی",
    ncByGenerated: "مشتری جدید به سفارش ثبت شده",
    finalizedByGenerated: "سفارش نهایی به سفارش ثبت شده",
    clickByGenerated: "کلیک به سفارش ثبت شده",
    filter: {
      today: "امروز",
      lastWeek: "هفته قبل",
      lastMonth: "ماه قبل",
      currentMonth: "ماه جاری",
      month_3: "سه ماهه",
      month_6: "شش ماهه",
      year: "یک ساله",
      favorite_date: "تاریخ دلخواه",
    },
    viewsAndClicks: "بازدید و کلیک",
    ordersCount: "تعداد سفارشات",
    doFilter: "اعمال فیلتر",
    paid_commission: "کمیسیون های پرداخت شده(تومان)",
    sure_commission: "کمیسیون قطعی شده(تومان)",
    not_sure_commission: "کمیسیون قطعی نشده(تومان)",
    invoiced_commission: "کمیسیون فاکتور شده(تومان)",
    finalized_commission: "کمیسیون نهایی شده(تومان)",
    event: "درآمد از جذب موفق",
    bonus: "پاداش",
    total_commission: "کل کمیسیون(تومان)",
    able_to_pay_commission: "کمیسیون های قابل پرداخت(تومان)",
    note1: "مختصات افقی تاریخ و مختصات عمودی تعداد را نمایش می دهد.",
    clicks: "کلیک",
    views: "بازدید",
    rate: "نرخ تبدیل",
    commissionTitle: "کمیسیون",
    commissions: "کمیسیون ها",
    topNotice: "اطلاعیه های مهم",
    myMedia: "رسانه ها و لینک های من",
    lorem: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
    mediaName: "نام رسانه",
    linkName: "عنوان لینک",
    mediaShare: "سهم رسانه",
    impressionCount: "نمایش",
    clickCount: "تعداد کلیک",
    finalClick: "تعداد کلیک نهایی",
    finalView: "تعداد بازدید نهایی(ویجت)",
    totalOrderAmount: "مجموع کل سفارشات(تومان)",
    commission: "کمیسیون",
    introAffilio: "با افیلیو بیشتر آشنا شویم",
    selectLinkType: "نوع لینک",
    orderTypeCategoryChart: "سفارشات برحسب دسته بندی",
    orderTypeLinkChart: "سفارشات برحسب نوع لینک",
    orderTypeMedia: "سفارشات برحسب نوع رسانه",
    submittedPublishers: "ناشر ثبت نام شده",
    finalizedPublishers: "ناشران تایید شده",
    submittedMedias: "رسانه های ثبت شده",
    generatedLinks: "لینک های تولید شده",
    submittedOrders: "سفارشات ثبت شده",
    finalizedOrders: "کمیسیون سفارشات نهایی شده",
    clicksCount: "تعداد کلیک‌ها",
    totalCommissions: "مبلغ کل کمیسیون",
    newCustomers: "مشتری های جدید",
    finalizedNewCustomers: "مشتری های جدید نهایی",
    y: "سفارشات برحسب رسانه",
    widget: "ویجت",
    banner: "بنر",
    link: "لینک",
    all: "همه",
    views_: {
      click_count: "کلیک",
      conversion_rate: "نرخ تبدیل",
      view_count: "بازدید",
    },
    value: "مقدار",
    com: {
      generated: "مبلغ کل سفارشات",
      generated_count: "تعداد کل سفارشات",
      net: "مبلغ خالص سفارشات ",
      net_count: "تعداد خالص سفارشات",
      finalized: "مبلغ نهایی سفارشات",
      finalized_count: "تعداد نهایی سفارشات",
      ncFinalized: "تعداد مشتریان جدید سفارشات نهایی شده",
      ncGenerated: "تعداد مشتریان جدید سفارشات ثبت شده",
      canceled: "مبلغ سفارشات کنسل شده",
      canceled_count: "تعداد سفارشات کنسل شده",
    },
    orderPriceChartTitle: "مبلغ سفارشات",
    commissionsTitle: "کمیسیون ها",
    viewClickTitle: "بازدید و کلیک",
    finalized: "قطعی شده",
    issued: "قطعی نشده",
    invoiced: "فاکتور شده",
    topTenInAffilio: "برترین های افیلیو",
    topGeneratedOverAll: "تعداد سفارشات ثبت شده ۱۰ ناشر برتر به کل سفارشات",
    topFinalOverAll: "تعداد سفارشات نهایی ۱۰ ناشر برتر به کل سفارشات",
    topCategoriesPrice: "دسته بندی های برتر",
    topCategoriesPriceSub: "(بر اساس مبلغ سفارشات نهایی)",
    topCategoriesPriceCountSub: "(بر اساس تعداد سفارشات)",
    select_link_type: "نوع لینک",
    InsightReport: "گزارش عملکرد افیلیو",
    user_type: "نوع کاربر",
  },
  factor: {
    preItemPublisher: "واگذاری فضای تبلیغات آنلاین",
    preItem: "خدمات سیستم همکاری در فروش سکوی رامان ",
    fromDate: "از تاریخ",
    toDate: "تا تاریخ",
    title: "صورت حساب فروش کالا و خدمات",
    title1: "فروشنده",
    store: "فروشنده",
    excel: "اکسل",
    nationalCode: "شناسه ملی",
    registerCode: "شماره ثبت",
    ecoCode: "شماره اقتصادی",
    address: "نشانی شخص/شرکت",
    address2: "نشانی",
    postalCode: "کد پستی",
    teleFax: "تلفن و فکس",
    invoiceId: "شماره فاکتور",
    date: "تاریخ",
    follow: "پیگیری",
    buyer: "خریدار",
    nationalCodeOrEco: "شماره اقتصادی/شماره ملی",
    rowId: "ردیف",
    idKala: "شناسه کالا یا خدمات",
    descKala: "شرح کالا یا خدمات",
    amer: "آمر",
    count: "تعداد",
    price: "مبلغ واحد(ریال)",
    total: "مبلغ کل(ریال)",
    discount: "تخفیف (ریال)",
    totalByDiscount: "مبلغ کل پس از تخفیف (ریال)",
    vat: "جمع مالیات و عوارض ارزش افزوده(ریال)",
    totalByAll: "جمع کل پس از تخفیف و مالیات عوارض (ریال)",
    sum: "جمع کل",
    sumNext: "جمع کل پس از کسر تخفیف با احتساب مالیات و عوارض (ریال)",
    sumVat: "اعتبار مالیاتی قابل استفاده توسط خریدار",
    signStore: "مهر و امضای فروشنده",
    signBuyer: "مهر و امضای خریدار",
    methodPayment: "روش های پرداخت",
    print: "پرینت",
  },
  formula: {
    titleMain: "تعریف FLOW",
    variables: "تعریف متغیرها",
    variablesDesc: "از قسمت زیر، متغیرهای مورد نظر خود را تعریف نمایید:",
    formulas: "تعریف فرمول ها",
    formulasDesc: "از قسمت زیر، فرمولهای مورد نظر خود را تعریف نمایید:",
    $productPrice: "قیمت محصول",
    $quantity: "تعداد محصول",
    $categoryCommissionPercent: "درصد کمیسیون دسته بندی",
    $commissionCeiling: "سقف کمیسیون",
    $newCustomer: "مشتری جدید",
    $orderVarcher: "میزان تخفیف",
    $productCommissionPercent: "درصد کمیسیون محصول",
    "@Prize": "@Prize",
    "@BaseCommission": "@BaseCommission",
    "@AffilioCommission": "@AffilioCommission",
    "@AffilioPrizeCommission": "@AffilioPrizeCommission",
    "@MerchantCommission": "@MerchantCommission",
    "@MerchantPrizeCommission": "@MerchantPrizeCommission",
    variablesCanUse: "متغیرهای قابل استفاده",
    formulesCanUse: "فرمولهای قابل استفاده",
    steps: "تعریف  گام های محاسبات",
    create_formula_text: "فرم تعریف فرمول",
    variableName: "variable name",
    variableValue: "value",
    stepName: "step name",
    Instruction: "instraction",
    Predicates: "predicates",
    assignableVariable: "سایر متغیرها",
    stepsDesc:
      "با استفاده از متغیرها و فرمولهای تعریف شده بالا، مراحل محاسبات را تعریف نمایید:",
    not_valid_must_set_value: "لطفا، یک متغیر یا فرمول انتخاب نمایید.",
    not_valid_must_set_operator: "لطفا، یک عملگر انتخاب کنید.",
    not_valid_formula: "لطفا، فرمول را صحیح وارد کنید.",
    define_flow: "تعریف FLOW",
    name: "عنوان فرمول",
    formula_object: "تعریف فرمول",
    info: "اطلاعات",
    is_recursive: "نمایش بازگشتی",
    is_recursive_desc:
      "در صورت فعال بودن این گزینه، فرمول تعریف شده، توسط سایر فروشنده ها قابل استفاده خواهد شد.",
    nameFlow: "عنوان",
  },
  payout: {
    startDate: "تاریخ شروع",
    endDate: "تاریخ پایان",
    invoiceInterval: "بازه ایجاد صورت حساب(روز)",
    paymentInterval: "بازه انجام پرداخت(روز)",
    statusPayment: "وضعیت",
    isActive: "فعال؟",
    has_invoice: "صورت حساب؟",
    id: "شناسه",
    true: "فعال",
    false: "غیرفعال",
    description: "توضیحات",
    history: "تاریخچه تغییر تنظیمات پرداخت",
    createPayout: "ایجاد تنظیمات پرداخت",
    interval_5: "۵ روز",
    interval_10: "۱۰ روز",
    interval_15: "۱۵ روز",
    interval_20: "۲۰ روز",
    interval_25: "۲۵ روز",
    interval_30: "۳۰ روز",
    sync: "یکپارچه سازی داده ها",
    status: {
      Waiting: "در انتظار پرداخت",
      Approved: "تایید شده",
      Rejected: "رد شده",
    },
  },
  payment: {
    id: "شناسه",
    detail: "جزئیات",
    is_active: "فعال؟",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    orderIndex: "تعداد سفارشات قابل قبول",
    affilioCommissionPercent: "درصد کمیسیون افیلیو",
    ceilingCommission: "سقف کمیسیون",
    defaultCommission: "کمیسیون پیش فرض",
    defaultPromotionCommission: "کمیسیون پیش فرض پروموشن",
    currency: "واحد محاسبات",
    productCurrency: "واحد محاسبات محصولات",
    calculationCategoryLevel: "سطح محاسبات",
    calculationCategoryLevelCustom: "سطح متغیر",
    costPerOrderByVoucher: "کمیسیون سفارش با کسر تخفیف",
    costPerOrder: "کمیسیون سفارشات",
    newCustomerRegistration: "ثبت نام مشتری جدید",
    newCustomerOrder: "سفارش مشتری جدید",
    bounty: "پاداش",
    createPayment: "ایجاد تنظیمات محاسبه",
    includeFollows: "انتخاب فرمول های محاسباتی",
    paymentHistory: "تاریخچه ویرایش تنظیمات",
    createForumla: "تعریف فرمول",
  },
  payoutMerchant: {
    id: "شناسه",
    payoutId: "شناسه پرداخت",
    merchantName: "فروشنده",
    invoice: "صورتحساب",
    tracking_code: "کد پیگیری",
    status: "وضعیت",
    payment_date: "تاریخ پرداخت",
    reject_date: "تاریخ رد",
    create_date: "تاریخ ایجاد",
    bill: "فایل رسید پرداخت",
    actions: "",
    list: "لیست پرداخت ها",
    createBill: "افزودن صورت حساب پرداخت",
    description: "توضیحات",
    transaction_id: "شناسه تراکنش",
    payment_date_from: "تاریخ پرداخت از",
    payment_date_end: "تاریخ پرداخت تا",
    setPaymentStatus: "ویرایش وضعیت پرداخت",
    nationalCode: "شناسه ملی شرکت",
  },
  payoutPublisher: {
    id: "شناسه",
    billLegal: "فایل فاکتور رسمی",
    invoiceId: "شناسه فاکتور",
    payoutId: "شناسه پرداخت",
    publisherName: "ناشر",
    sheba: "شماره شبا",
    invoice: "فاکتور",
    tracking_code: "کد پیگیری",
    status: "وضعیت فاکتور",
    payment_date: "تاریخ پرداخت",
    reject_date: "تاریخ رد",
    create_date: "تاریخ ایجاد",
    bill: "فایل رسید پرداخت",
    actions: "",
    list: "لیست پرداخت ها",
    description: "توضیحات",
    transaction_id: "شناسه تراکنش",
    setPaymentStatus: "ویرایش وضعیت پرداخت",
    setStatus: "ویرایش وضعیت",
    contract_status: "وضعیت قرارداد",
    payment_status: "وضعیت پرداخت",
    total: "مبلغ کل",
    last_modify_date: "تاریخ آخرین بروزرسانی",
    create_date_from: "تاریخ ایجاد از",
    create_date_end: "تاریخ ایجاد تا",
    nationalCode: "کد ملی ناشر/شرکت",
  },
  widgetSmart: {
    title: "عنوان ویجت",
    website: "فروشگاه",
    category: "دسته بندی",
    media: "رسانه",
    categoryType: "انتخاب نوع ویجت",
    helpTextCategory: "ایجاد ویجت براساس:",
    titleWidget: "ویجت",
    createWidget: "تولید کد",
    editWidget: "ویرایش ویجت",
    sureForUseWidget: "جهت استفاده از این ویجت مطمئن هستید؟",
    messageSelectMedia: "لطفا، رسانه مورد نظر خود را انتخاب کنید",
    helpText: `برای استفاده از اسمارت ویجت ها
       باید رسانه مورد نظر خود را انتخاب کنید،
       ویجت ساخته شده به صورت اختصاصی برای آن رسانه ساخته میشود.
       `,
    selectMedia: "انتخاب رسانه",
  },
  grouping: {
    id: "شناسه",
    name: "نام تگ",
    create_date: "تاریخ ایجاد",
    last_modify_date: "تاریخ بروزرسانی",
    is_enabled: "وضعیت",
    delete: "حذف",
    add: "افزودن تگ",
    edit: "ویرایش",
    editTitle: "ویرایش تگ",
    selectWebsite: " فروشگاه",
    website: "فروشگاه",
    status: {
      true: "فعال",
      false: "غیرفعال",
    },
  },
  report: {
    report_id: "شناسه گزارش",
    create_date: "تاریخ ایجاد",
    last_modify_date: "تاریخ بروزرسانی",
    user_id: "شناسه کاربر",
    report_type: "نوع گزارش",
    status: "وضعیت",
    download: "دانلود",
    percent: "درصد",
    statusOptions: {
      QUEUED: "در صف پردازش",
      FAILED: "خطا",
      SUCCEEDED: "پردازش شده",
      IN_PROGRESS: "در حال پردازش",
    },
    affilio_commission_for_orders: "کمیسیون افیلو برای سفارشات ثبت شده",
    date_c_from: "تاریخ ایجاد از",
    date_c_end: "تاریخ ایجاد تا",
    date_u_from: "تاریخ بروزرسانی از",
    date_u_end: "تاریخ بروزرسانی تا",
    orderType: {
      ORDER: "سفارش",
      MEDIA: "رسانه",
      LINK: "لینک",
    },
    reportSuccessFullyRetried: "گزارش مجددا در صف پردازش قرار گرفت!",
    InsightReport: "گزارش عملکرد افیلیو",
    reportOrders: "گزارش سفارشات",
    title: "عنوان",
    price: "مبلغ(تومان)",
    diff: "تغییر",
    count: "تعداد",
    reportCommissions: "گزارش کمیسیون ها",
    reportNewCustomers: "آمار مشتریان جدید",
    reportRate: "گزارش نرخ ها",
  },
  rp: {
    generated_orders_amount: "تعداد سفارشات ثبت شده",
    accepted_orders_amount: "تعداد سفارشات تایید شده در فروشگاه",
    canceled_orders_amount: "تعداد کل سفارشات کنسل شده",
    finalized_orders_amount: "تعداد کل سفارشات نهایی شده",
    generated_orders: "مبلغ کل سفارشات ثبت شده",
    accepted_orders: "مبلغ سفارشات تایید شده در فروشگاه",
    canceled_orders: "مبلغ کل سفارشات کنسل شده",
    finalized_orders: "مبلغ کل سفارشات نهایی شده",
    com_generated_order_pub: "مبلغ کمیسیون سفارشات ثبت شده ناشران",
    com_accepted_order_pub: "مبلغ کمیسیون سفارشات تایید شده ناشران",
    com_canceled_order_pub: "مبلغ کمیسیون سفارشات کنسل شده ناشران",
    com_finalized_order_pub: "مبلغ کمیسیون سفارشات نهایی شده ناشران",
    com_generated_order_aff: "مبلغ کمیسیون افیلیو برای سفارشات ثبت شده",
    com_accepted_order_aff: "مبلغ کمیسیون افیلیو برای سفارشات تایید شده",
    com_canceled_order_aff: "مبلغ کمیسیون افیلیو برای سفارشات کنسل شده",
    com_finalized_order_aff: "مبلغ کمیسیون افیلیو سفارشات نهایی شده",
    nc_over_gene_orders: "نسبت تعداد مشتریان جدید به کل سفارشات ثبت ‌شده",
    nc_over_approved_orders:
      "نسبت تعداد مشتریان جدید به سفارشات تایید‌شده در فروشگاه",
    nc_over_canceled_orders: "نسبت تعداد مشتریان جدید به سفارشات کنسل‌شده",
    nc_over_finalized_orders: "نسبت تعداد مشتریان جدید به سفارشات نهایی‌شده",
    order_new_nc_over_finalized_orders:
      "تعداد سفارشات نهایی‌شده از مشتریان جدید به کل سفارشات نهایی‌شده",
    com_generated_order_over_generated_order:
      "نسبت کمیسیون سفارشات ثبت‌شده ناشران به مبلغ کل سفارشات ثبت‌شده",
    com_approved_order_over_approved_order:
      "نسبت کمیسیون سفارشات تاییدشده در فروشگاه به مبلغ کل سفارشات تایید‌شده در فروشگاه",
    com_cancel_order_over_cancel_order:
      "نسبت کمیسیون سفارشات کنسل‌شده ناشران به مبلغ کل سفارشات کنسل‌شده",
    com_final_order_over_final_order:
      "نسبت کمیسیون سفارشات نهایی‌شده ناشران به مبلغ کل سفارشات نهایی‌شده",
    approved_order_over_gen_order:
      "نسبت سفارشات تاییدشده در فروشگاه به کل سفارشات ثبت‌شده",
    canceled_order_over_gen_order:
      "نسبت سفارشات کنسل‌شده به کل سفارشات ثبت‌شده",
  },
  help: {
    tourTitleModal: "راهنمای گام به گام",
    back: "قبلی",
    next: "بعدی",
    end: "پایان",
    description: "هر گزینه، مراحل انجام کار را به صورت گام به گام نشان می‌دهد.",
    help: "راهنما",
    more: "برای اطلاعات بیشتر صفحه راهنما را ببینید!",
  },
  campaign: {
    CampaignPageTitle: "کمپین ها",
    id: "شناسه",
    name: "نام کمپین",
    web_store: "فروشگاه",
    type: "نوع کمپین",
    start_date: "تاریخ شروع",
    end_date: "تاریخ پایان",
    status: "وضعیت",
    active_de_active: "فعال / غیرفعال",
    campaignInfo: "اطلاعات کمپین",
    selectWidget: "انتخاب ویجت",
    selectBanner: "انتخاب بنر",
    selectMember: "مخاطبین کمپین",
    registerEnd: "ثبت نهایی",
    calculateCampaign: "نحوه محاسبه کمیسیون",
    commissionMax: "سقف کمیسیون",
    subject: "زمینه فعالیت کمپین",
    add_member: "افزودن مخاطب",
    payment_config_general: "تنظیمات کمیسیون کمپین",
    payment_config_specific: "تنظیمات کمیسیون براساس محصول یا دسته بندی خاص",
    description:
      "درصورتی که میخواهید، کمیسیون ها بر روی کل کمپین اعمال شود، فرم زیر را تکمیل کنید",
    category_name: "نام دسته",
    product_name: "نام محصول",
    event_name: "نوع کمیسیون",
    commission_publisher: "کمیسیون ناشر",
    commission_affilio: "کمیسیون افیلیو",
    delete: "حذف",
    add_product: "افزودن کمیسیون محصول",
    add_category: "افزودن کمیسیون دسته بندی",
    commission_types: {
      Percent: "درصد",
      FixPrice: "قیمت",
      Formula: "فرمول",
    },
    show_widget: "مشاهده ویجت",
    cal_types: {
      Any: "عمومی",
      Category: "دسته بندی",
      Product: "محصول",
      Both: "محصول و دسته بندی",
    },
    event_types: {
      CostPerOrder: "کمیسیون هر سفارش",
      NewCustomer: "کمیسیون مشتری جدید",
    },
    payment_config: "تنظیمات کلی محاسبه کمیسیون",
    payment_config_per_item:
      "درصورتی که میخواهید، محاسبه کمیسیون برروی دسته بندی یا محصول خاصی اعمال شود،‌ فرم زیر را تکمیل کنید",
    statusTypes: {
      Active: "فعال",
      Waiting: "در انتظار",
      Pause: "متوقف شد",
      Ended: "پایان یافته",
    },
    is_in_campaign: "فعال در کمپین",
    add_any: "افزودن کمیسیون",
    selectType: " نوع کمپین",
    edit: "ویرایش",
    both: "هردو",
    next: "ثبت و ادامه",
    prev: "قبل",
    smart_widget_name: "نام اسمارت ویجت",
    number_product: "تعداد محصول",
    create_date: "تاریخ ایجاد",
    category: "دسته بندی",
    detail: "جزئیات",
    textAddCampaign:
      "در صورتی که اسمارت ویجت مورد نظر شما در لیست زیر موجود نیست، دکمه روبرو را کلیک کنید.",
    textAddBanner:
      "در صورتی که بنر مورد نظر شما در لیست زیر موجود نیست، دکمه روبرو را کلیک کنید.",
    createWidget: "ایجاد ویجت جدید",
    bannerWidget: "ایجاد بنر جدید",
    smart_banner_name: "عنوان بنر",
    smart_banner_image: "بنر",
    detail_campaign: "جزئیات کمپین",
    smart_selected: "اسمارت ویجت های انتخابی",
    banner_selected: "بنرهای انتخابی",
    sureRun: "کمپین اجرا شود؟",
    sureHold: "کمپین متوقف شود؟",
    sureStop: "کمپین پایان یابد؟",
    endSubmit: "ثبت و پایان",
    member_type: "نوع مخاطب",
    member_name: "نام مخاطب",
    memberTypes: {
      Bucket: "سطح کاربر",
      Publisher: "ناشر",
      Media: "رسانه",
      Level: "سطح فروشگاه",
    },
    skip: "رد کن",
    usedInOtherCamp: "کمپین",
    usedInCurrentCamp: "کمپین جاری",
    sure_for_remove_from_item: "برای حذف این آیتم از کمپین مطمئن هستید؟",
    limitedAddMessage: "خطا، امکان افزودن ردیف جدیدی وجود ندارد!",
  },
  bucket: {
    Diamond: "برنزی",
    Gold: "طلایی",
    Silver: "نقره ای",
    Bronze: "برنزی",
  },
  invoice_document: {
    id: "شناسه فایل",
    name: "عنوان سند",
    create_date: "تاریخ ایجاد",
    remove: "حذف",
    re_generate_invoice: "ایجاد مجدد فاکتور",
    please_upload_documents_invoice:
      "لطفا، مدارک و مستندات خود را از این قسمت آپلود کنید:",
    title_doc: "آپلود مستندات",
    title_doc_admin: "پیوست ها",
    title_button: "آپلود مستندات",
    title_button_admin: "پیوست ها",
    submit: "ثبت مدارک",
    payment_doc: "",
    download: "دانلود",
    sure_for_remove: "برای حذف این سند، مطمئن هستید؟",
    deleted: "حذف شد.",
  },
  reportMedia: {
    publisher_id: "شناسه ناشر",
    publisher_name: "ناشر",
    media_name: "رسانه",
    media_id: "شناسه رسانه",
    media_address: "آدرس رسانه",
    total_click: "کل کلیکها",
    total_order: "کل سفارشات",
    total_merchant_approved_order: "سفارشات تایید شده",
    total_merchant_rejected_order: "سفارشات رد شده",
    total_new_order: "سفارشات جدید",
    total_cancel: "سفارشات لغو شده",
    total_new_customer: "تعداد مشتریان جدید (NC)",
    from_order_date: "تاریخ سفارش از",
    to_order_date: "تاریخ سفارش تا",
    from_click_date: "تاریخ کلیک از",
    to_click_date: "تاریخ کلیک تا",
    AOV: "میانگین سفارشات (AOV)",
    finalized_order_rate: "نرخ نهایی شدن سفارش",
    new_customer_rate: "نرخ مشتری جدید (NCR)",
    new_customer_share: "نرخ مشتری جدید (نسبت به کل)",
    NMV: "مبلغ سفارشات (NMV)",
    commission_share: "میزان کمیسیون (نسبت به کل)",
    commission_to_nmv_rate: "نسبت کمیسیون به مبلغ سفارشات",
    nmv_share: "میزان مبلغ سفارشات (نسبت به کل)",
    commission: "کمیسیون کل سفارشات",
    aov_finalize: "میانگین نهایی سفارشات",
    nmv_finalize: "مبلغ نهایی سفارشات",
    media_level: "سطح رسانه",
    total_finalize_order: "تعداد نهایی سفارشات",
    total_finalize_new_customer: "تعداد نهایی مشتری جدید",
    finalize_commission: "کمیسیون سفارشات نهایی شده",
  },
  formulas: {
    id: "شناسه",
    name: "نام",
    merchant_name: "فروشنده",
    web_store_name: "فروشگاه",
    is_recursive: "نمایش بازگشتی",
    display_formula: "نمایش فرمول",
    create_date: "تاریخ ایجاد",
    last_modify_date: "تاریخ بروزرسانی",
    actions: "",
    flow_json: "نمایش FLOW",
  },
  widget_builder: {
    selectWidget: "انتخاب ویجت آماده",
    selectWidgetButton: "انتخاب ویجت",
    myWidgets: "ویجت های من",
    select_store: "انتخاب فروشگاه",
    select_widget: "انتخاب ویجت",
    select_products: "انتخاب محصولات",
    select_store_between:
      "لطفا از بین فروشگاه های زیر یک فروشگاه انتخاب نمایید:",
    select_store_between_merchant:
      "جهت انتخاب محصول، فروشگاه مورد نظر خود را انتخاب کنید",
    select_widget_from_below: "ویجت مورد نظر خود را از لیست زیر انتخاب کنید",
    select_products_from_below:
      "برای ایجاد ویجت، محصولات مورد نظر خورد را از لیست محصولات این فروشگاه انتخاب کنید",
    selectedWidget: "انتخاب شده",
    create_widget: "ساخت ویجت دلخواه",
    empty_widget:
      "این فروشگاه ویجت آماده ای ندارد، میتوانید از طریق لینک روبرو برای این فروشگاه ویجت ایجاد کنید.",
    no_widget_message: {
      message: `در صورتی که ویجت مورد نظر شما در لیست ویجت های آماده بالا نبود، از طریق `,
      message2: "بخش ایجاد ویجت",
      message3: "، ویجت مورد نظر خود را ایجاد نمایید!",
    },
    helpTextTitle: "ایجاد و مدیریت ویجتهای ساخته شده",
    widgetSetting: "تنظیمات ویجت",
    showInWhichMediaText:
      "این ویجت در کدام رسانه/رسانه های شما نمایش داده شود؟",
    selectCategory: "انتخاب دسته بندی",
    displayConfig: "تنظیمات نمایشی",
    view_in_desktop: "نحوه نمایش در دسکتاپ",
    nameWidget: "عنوان ویجت",
    show_name: "نمایش عنوان",
    Grid: "گرید",
    Column: "ستونی",
    Row: "افقی",
    editWidget: "ویرایش ویجت",
    change_name: "عنوان ویجت",
    color_button: "رنگ دکمه",
    color_title_button: "رنگ عنوان دکمه",
    show_product_title: "نمایش عنوان محصول",
    show_product_button: "نمایش دکمه محصول",
    show_price_button: "نمایش قیمت محصول",
    generated_code: "کدهای تولید شده",
    product_id: "شناسه محصول",
    select_category: "انتخاب دسته بندی",
    show_campaign_widget: "نمایش ویجت های کمپینی",
    campaign_title: "کمپین",
    successfullyCreated: "ویجت با موفقیت ایجاد شد!",
    successfullyUpdated: "ویجت با موفقیت اپدیت شد!",
    showCreatedWidgets: "بازگشت به لیست ویجتها",
    addNewWidget: "ایجاد ویجت از محصولات فروشگاه",
    selectNewWidget: "انتخاب ویجت از ویجت های آماده فروشگاه ها",
    error_select_title: "لطفا عنوان ویجت رو مشخص کنید!",
    error_select_tag: "لطفا حداقل یک تگ برای این ویجت انتخاب کنید!",
    detail_widget: "اطلاعات و جزئیات ویجت",
  },
  sidebar: {
    home: "داشبورد",
    "affiliate-tools": "ابزارهای افیلیت",
    "website-parties": "فروشگاه های همکار",
    finance: "مالی",
    other: "سایر",
    "aff-suggest": "پیشنهاد افیلیو",
  },
  required: "الزامی",
  loading: "...",
  Action: "جزئیات",
  search: "جستجو",
  unknown: "نامشخص",
  yes: "بله",
  no: "خیر",
  ssp: {},
  ticket: {
    ticketTitle: "تیکت پشتیبانی",
    ticketContact: "تماس با پشتیبانی",
    helpText:
      "برای راهنمایی و همراهی بیش‌تر شما می‌توانید از روش‌های زیر با ما در ارتباط باشید",
    telegram: "تلگرام",
    email: "ایمیل",
    instagram: "اینستاگرام",
  },
  alert: {
    dashboardAlert:
      "ناشر گرامی، به روز رسانی اطلاعات پیشخوان هر 24 ساعت یکبار انجام می شود. برای مشاهده آمار دقیق تر، پیشنهاد می کنیم گزارشات مربوط به قبل از 24 ساعت گذشته را بررسی نمایید.",
  },
};

export default faTranslation;
