import { phoneNumberDetail } from "@persian-tools/persian-tools";
import { message } from "antd";
import axios from "axios";
import * as blobUtil from "blob-util";
import CryptoJS from "crypto-js";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment-jalaali";
import propTypes from "prop-types";
import { matchPath } from "react-router-dom";

import {
  AUTHENTICATED_ROUTES,
  PUBLIC_ROUTES,
  getPrivateRoutes,
} from "@/app/routes";
import {
  AUTHENTICATED_ROUTES as AUTHENTICATED_ROUTES_,
  getPrivateRoutes as getPrivateRoutesSSP,
} from "@/app/routes-ssp";

import {
  CONTRACT_STATUS,
  FIRST_APPROVE_ACCEPTED,
  LANGUAGE,
  LEGAL_STATUS,
  ProfileInfoErrorList,
  USER_CONFIG_TYPE,
  USER_STATUS,
  USER_TYPE,
  WEB_STORE_AMOUNT_TYPE,
} from "./constants/apiConstants";
import { MIG_MIG_API, SHORTNER_BASE_LINK } from "./constants/configConstants";
import { clearStorage } from "./utils";

export const PropTypes = propTypes;

export const getLastError = (error) => {
  try {
    const title = error?.data?.title || "";
    if (title.includes("validation errors occurred")) {
      return t("general.ValidationError");
    }

    const errors = _.get(error, "data.errors", {});
    const messages = Object.values(errors);
    if (messages.length) {
      return messages.flat()[0];
    }
    return t("general.UnexpectedErrorOccurred");
  } catch (e) {
    return t("general.UnexpectedErrorOccurred");
  }
};

export const disabledDate = (current, type = "toNow") => {
  // Can not select days before today and today
  if (type === "toNow") {
    return current && current > moment().endOf("day");
  } else if (type === "fromNow") {
    return current && current < moment().endOf("day");
  }
};

export const objectR = (obj) =>
  obj && Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const customUploadFile = (
  file,
  fileType,
  mediaType,
  objectId,
  objectType
) => {
  const fileInfo = {
    uploader: file,
    fileType: fileType,
    mediaType: mediaType,
    objectId: objectId,
    objectType: objectType,
  };

  return fileInfo;
};

export const saveFile = async (data) => {
  const { b64, fileName, mType, file } = data;

  if (file?.use_public_domain) {
    const url = file.public_path;
    const a = document.createElement("A");
    a.href = url;
    a.download = url.substr(url.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return;
  } else {
    const blob = blobUtil.base64StringToBlob(b64, mType);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
};

export const getUrlFile = (data) => {
  const { source: b64, user_defined_name: fileName, mime_type: mType } = data;
  const blob = blobUtil.base64StringToBlob(b64, mType);
  const url = window.URL.createObjectURL(blob);
  return url;
};

export const serverAcceptableDate = (date, startDay, endDay) => {
  if (startDay) {
    return `${moment.utc(moment(new Date(date)).startOf("day")).format()}`;
  }
  if (endDay) {
    return `${moment.utc(moment(new Date(date)).endOf("day")).format()}`;
  }
  return `${moment.utc(new Date(date)).format()}`;
};

export const formatDate = (momentDate) =>
  `${momentDate.format("YYYY-MM-DDTHH:mm:ss")}`;

export const convertToAntDate = (date, type) => {
  if (!date) return null;
  const cuLocal = localStorage.getItem("locale");

  if (cuLocal && cuLocal === LANGUAGE.ENGLISH) {
    return moment(date).format("YYYY-MM-DD");
  }

  // let dt_ = moment(date).format("jYYYY-jMM-jDD");
  // if (type === "FULL") {
  //   dt_ = moment(date).format("jYYYY-jMM-jDD HH:mm:ss");
  // }
  return moment(date);
};

export const REGEX_NUMERIC = /^\d+$/;

export const CURRENCY_FORMAT_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export const validation = (value, type) => {
  let isValid;
  switch (type) {
    case "VALID_NATIONAL_ID":
      isValid = value.match(REGEX_NUMERIC);
      break;
    case "VALID_ECONOMICAL_ID":
      isValid = value.match(REGEX_NUMERIC);
      break;
    case "MOBILE":
      isValid = phoneNumberDetail(value);
      break;
  }
  return isValid;
};

export const routeMiddleware = (usr_, navigate, location) => {
  const usrType = usr_?.user_type;
  const isAdmin =
    usrType === USER_TYPE.SUPER_ADMIN || usrType === USER_TYPE.ADMIN;
  const pth = location?.pathname;

  if (usrType && pth) {
    let d = AUTHENTICATED_ROUTES;
    if (getPanelMode() === "SSP") {
      d = AUTHENTICATED_ROUTES_;
    }
    const userHasAccess = d.find((item) => {
      const isExistPath = getMatchPath(pth, item.path);
      if (isExistPath) {
        return !item.restrict?.find((i) => i === usrType);
      }
    });
    if (!userHasAccess) {
      // return <Navigate to="403" />;
      navigate("/403");
      return;
    }
  }
  if (!isAdmin) {
    const userConfigType = usr_?.config?.find(
      (item) => item.type === USER_CONFIG_TYPE.FirstApprove
    );

    const firstLoginStatus = usr_?.config?.find(
      (item) => item.type === USER_CONFIG_TYPE.FirstLogin
    );
    if (firstLoginStatus?.value === "need-reset-password") {
      window.location.href = "/reset-password";
      return;
    }
    if (usr_.status === USER_STATUS.Confirmed) {
      if (pth === "/wizard") {
        navigate("/");
      }
      if (usr_.contract_status === CONTRACT_STATUS.NEW) {
        navigate("/profile?tabId=contract");
        return;
      }
      return;
    }

    if (!userConfigType || userConfigType.value !== FIRST_APPROVE_ACCEPTED) {
      navigate("/wizard");
      return;
    } else {
      if (usr_.status !== USER_STATUS.Confirmed) {
        navigate("/profile");
        return;
      }
    }
  }
};

// format(123456789, "## ## ## ###"));
export const formatString = (value, pattern) => {
  let val_ = value;
  let pat_ = pattern;
  if (pattern === "mobile") {
    val_ = "0" + val_;
    pat_ = "#### ### ####";
  }
  let i = 0,
    v = val_.toString();
  return pat_.replace(/#/g, (_) => v[i++]);
};

export const getUserStatus = (statusId) => {
  return objectR(USER_STATUS)[statusId] || "unknown";
};

export const getContractStatus = (statusId) => {
  return objectR(CONTRACT_STATUS)[statusId] || "unknown";
};

export const setInputPrivileges = (prefixInput, inputs, form_) => {
  inputs.forEach((inputName) => {
    const INP = form_.getFieldInstance([prefixInput, inputName]);
    if (INP?.input) {
      INP.input.readOnly = true;
      return;
    }
    if (INP?.state?.input) {
      INP.state.input.disabled = true;
      return;
    }
  });
};

export const getTimeStamp = () => {
  const d = new Date();
  return d.getTime();
};

export const getMatchPath = (pathname, pattern) => {
  const matchProfile = matchPath(
    {
      path: pattern,
      exact: false,
      strict: false,
    },
    pathname
  );
  return matchProfile;
};

export const dateFormat = (date, format = "S") => {
  if (!date) return "-";
  const locale = localStorage.getItem("locale");

  if (locale === LANGUAGE.ENGLISH) {
    if (format === "S") {
      return moment(date).format("YYYY-MM-DD");
    } else if (format === "ST") {
      return moment(date).format("YYYY-MM-DD HH:mm");
    } else if (format === "S2") {
      return moment(date).format("YYYY/MM/DD");
    }
  }

  moment.loadPersian();
  if (format === "S") {
    return moment(date).format("jDD jMMMM jYYYY");
  } else if (format === "ST") {
    return (
      moment(date).format("HH:mm ") +
      "  " +
      moment(date).format("jYYYY-jMM-jDD")
    );
  } else if (format === "S2") {
    return moment(date).format("jYYYY.jMM.jDD");
  } else if (format === "SD") {
    return moment(date).format("jMM.jDD");
  } else if (format === "SF") {
    return moment(date).format("jYYYY-jMM-jDD");
  } else if (format === "SFS") {
    return moment(date).format("jYYYY/jMM/jDD");
  }

  return "-";
};

export const isLegalUser = (user_) => {
  const user = user_?.data;
  const detail = user_?.userDetail;
  if (user?.user_type === USER_TYPE.SUPER_ADMIN) {
    return true;
  }
  if (user?.user_type === USER_TYPE.MERCHANT) {
    return true;
  } else {
    if (detail?.legal_status === LEGAL_STATUS.LEGAL) {
      return true;
    }
  }
  return false;
};

export const getUrl = (url_) => {
  if (!url_) {
    return {
      type: "",
      url: "",
    };
  }

  const regex = /http(|s):\/\//g;
  const type = url_.match(regex);
  const url = url_.replace(type, "");
  return { type, url };
};

export const buildTree = (data_) => {
  const arrayToTree = (arr, parent = undefined) =>
    arr
      .filter((item) => item.parent_id === parent)
      .map((child) => ({
        ...child,
        key: child.Category_id,
        title: child.name,
        children: arrayToTree(arr, child.Category_id),
      }));
  return arrayToTree(data_);
};

export const priceFormat = (price, currency) => {
  try {
    if (_.isNull(price) || _.isUndefined(price)) {
      return "-";
    }
    // if (price?.is_availbel === 0) {
    //   return <b className="unavailable">{t("general.unavailable")}</b>;
    // }
    if (currency === "N") {
      // N stand Number
      // Format price in the pattern 111,1111
      let amount = price?.display_amount ? price?.amount : price;
      return new Intl.NumberFormat().format(amount);
    }

    if (currency === "NT") {
      // NT stand Number Toman
      // Format price in the pattern 111,1111 toman
      return `${new Intl.NumberFormat().format(price)} ${t("general.Toman")}`;
    }

    if (currency === "C") {
      // C stand Clean
      // Extract the numeric part from the price string
      let amount = price?.display_amount || price;
      const rg = new RegExp(/(.*)\d/g);
      return amount.match(rg)[0];
    }

    if (_.isObject(price)) {
      // Return the display_amount for object price
      if (currency === "display_amount_with_discount") {
        return price?.display_amount_with_discount;
      }
      return price?.display_amount;
    }

    return price;
  } catch (e) {
    return 0;
  }
};

export const numberFormat = (price, currency, type) => {
  try {
    let price_ = price;
    if (!price_) return 0;
    if (!Number.isInteger(price) && Number(price)) {
      price_ = price.toFixed(2);
    }
    const priceString = String(price_);
    const number_ = new Intl.NumberFormat().format(priceString);
    if (type === "percent") {
      return `%${number_}`;
    }
    return number_;
  } catch (e) {
    return price;
  }
};

export const cleanUrl = (url) => {
  if (!url) return "";
  return url.replace("https://", "").replace("http://", "");
};

export const calcPercentDiscount = (price) => {
  if (price?.discount) {
    if (price.amount_type === WEB_STORE_AMOUNT_TYPE.OriginalAmount) {
      if ((price.discount / price.amount) * 100 > 1) {
        return `${((price.discount / price.amount) * 100).toFixed(1)}%`;
      }
    } else if (price.amount_type === WEB_STORE_AMOUNT_TYPE.AmountWithDiscount) {
      if ((price.discount / (price.amount + price.discount)) * 100 > 1) {
        return `${(
          (price.discount / (price.amount + price.discount)) *
          100
        ).toFixed(1)}%`;
      }
    }
    return "~1%";
  }
};

export const getCookie = (key) => {
  // var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  // return b ? b.pop() : "";
  // return Cookies.get(key);
};

export const getPreviewCode = (id, category) => `
    <!-- Affilio Widget BEGIN -->
    <div class="affilio-widget-container">
        <div id="affilio_${id}"></div>
        <script type="text/javascript" src="http://localhost:8080/lib.js"></script>
        <script type="text/javascript">
          Affilio.widget({autosize: true, container_id: "affilio_${id}"})
        </script>
    </div>
    <!-- Affilio Widget END -->
`;

export const getLinkMigMig = (type, key, token) => {
  const baseLink = MIG_MIG_API;
  // 1rBKZ?b64=base64_coded_url
  let endPoint = "";
  switch (type) {
    case "Widget":
      endPoint = `${baseLink}/w/${key}`;
      break;
    case "Private":
      endPoint = `${baseLink}/${key}`;
      break;
    case "Public":
      // eslint-disable-next-line no-case-declarations
      const token_ = token ? `?b64={YOUR_BASE64_CODE}` : "";
      endPoint = `${baseLink}/b/${key}${token_}`;
      break;
  }
  return endPoint;
};

export const getShortLink = (key) => {
  return `${SHORTNER_BASE_LINK}${key}`;
};

export const isEmptyObject = (obj) => {
  return _.isEmpty(_.omitBy(obj, _.isNil));
};

export const hasAccess = (user, privileges) => {
  const pv = privileges?.find((i) =>
    user?.privileges?.find((j) => j.privilege_id === i)
  );
  return !!pv;
};

export const kFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    // { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  if (item) {
    const v = (num / item.value).toFixed(digits).replace(rx, "$1");
    const a = new Intl.NumberFormat().format(v);
    return a + item.symbol;
  }
  return "0";
};

export const sortDate = (items) => {
  return [...(items || [])]?.sort((a, b) => {
    const isAfter = moment(a.pivot).isAfter(b.pivot);
    if (isAfter) return 1;
    return -1;
  });
};

export const stripHtmlText = (txt) => {
  try {
    let doc = new DOMParser().parseFromString(txt, "text/html");
    return doc.body.textContent || "";
  } catch (e) {
    // GET LOG
    return "";
  }
};

export const decryptPass = (txt) => {
  try {
    const encryptedText = "PU/GjIjC5y3vQdTLnhv5Vw==";
    const txt_ = "9130238277";
    const key = "ePQVGwr06ncEp2THRq48L7qvaARidH7L";
    const decrypted = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
      keySize: 32,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.ECB,
    }); // Message
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    const catching = "catch";
  }
};

export const titleHandler = (role) => {
  try {
    const currentPage = location?.pathname;
    let currentPageName;
    const isSSP = getPanelMode() === "SSP";

    let mainTitle = isSSP ? t("general.titleSiteSSP") : t("general.titleSite");

    if (role !== undefined) {
      const routes = isSSP ? getPrivateRoutesSSP(role) : getPrivateRoutes(role);

      const pageInfo = routes.find((pth) =>
        getMatchPath(currentPage, pth?.path)
      );

      currentPageName = pageInfo?.title;
      document.title = mainTitle + " - " + t("page." + currentPageName);
    } else {
      const routeMatched = PUBLIC_ROUTES.find(
        (item) => item.path === currentPage
      );
      if (!routeMatched || routeMatched.path === "/")
        document.title = mainTitle;
      else {
        document.title = mainTitle + " - " + t("page." + routeMatched.name);
      }
    }
  } catch (e) {
    const catching = "catch";
  }
};

export const errorHanding = (callback) => {
  try {
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (err?.response?.status >= 500) {
          message.error({
            content: `${t("general.networkError")}`,
            key: "NET_ERROR",
            className: "app_main_error",
            duration: 3,
          });
        } else if (err?.response?.status === 403) {
          message.error({
            content: `${t("general.networkErrorAccess")}`,
            key: "NET_ERROR",
            className: "app_main_error",
            duration: 3,
          });
        } else if (err?.response?.status === 401) {
          if (window.location.pathname !== "/login") {
            message.error({
              content: `${t("general.networkErrorNeedAuth")}`,
              key: "NET_ERROR",
              className: "app_main_error",
              duration: 3,
            });

            if (localStorage.getItem("lts-login")) {
              setTimeout(async () => {
                const result = await callback();
                if (!result?.payload?.success) {
                  clearStorage();
                  window.location.href = "/login";
                }
              }, 1000);
            }
          }
        }
        return Promise.reject(err);
      }
    );
  } catch (e) {
    const catching = "catch";
  }
};

export const hashCode = (s) => {
  try {
    return s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  } catch (e) {
    return s;
  }
};

export const getHostname = (url, replace) => {
  const host = new URL(url)?.host;
  if (host.includes("www.")) {
    return host?.replace("www.", "@");
  } else return replace + host;
};

export const sanitizeObject = (obj) => {
  const obj_ = { ...obj };
  Object.keys(obj_).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  if (_.isEmpty(obj_)) {
    return undefined;
  }
  return obj_;
};

export const findInfoError = (errors) => {
  try {
    let keyName;
    keyName = Object.keys(errors).filter(
      (item) => item === ProfileInfoErrorList[item]
    );
    return keyName;
  } catch (e) {
    const catching = "catch";
  }
};

export const setLocalLang = (v) => {
  localStorage.setItem("locale", v);
};

export const getLocalLang = () => {
  return localStorage.getItem("locale") || LANGUAGE.PERSIAN;
};

export const getTz = () => {
  const tz = moment().format("Z");
  return tz;
};

export const getCampId = (pathId) => {
  if (pathId === "new") {
    return null;
  }
  return pathId;
};

export const getTableData = (dataItems) => {
  const pages = _.get(dataItems, "data.total_pages", 1);
  const page = _.get(dataItems, "data.page", 1);
  const pageSize = _.get(dataItems, "data.page_size", 10);
  const items = _.get(dataItems, "data.total_items", 10);

  // const tableInfo = { items, pages, pageSize, serverPagination: true, page };

  const tableInfo = {
    items,
    pages,
    pageSize,
    serverPagination: true,
    page,
  };

  return { pages, page, pageSize, tableInfo };
};

export const handleDate = (pivot, prevPivot) => {
  const prevYear = prevPivot && parseInt(dateFormat(prevPivot, "S2"));
  const currentYear = parseInt(dateFormat(pivot, "S2"));
  let date;
  if (prevYear === currentYear) {
    date = dateFormat(pivot, "S2");
  } else {
    date = dateFormat(pivot, "S2");
  }
  return date;
};

export const percentDisplay = (next, current) => {
  if (!next) return null;
  return ((next - current) / (current || 1)) * 100;
};

export const showPercent = (v) => {
  return _.isNumber(v) ? `${v * 100}%` : null;
};

export const exportCsvMain = (rows) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "my_data.csv");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
};

function fn_(value) {
  return value.toString().padStart(2, "0");
}

export const getCountDownTimer = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const diff = moment.duration(end.diff(start));

  const days = Math.floor(diff.asDays());
  const hours = Math.floor(diff.asHours()) % 24;
  const minutes = Math.floor(diff.asMinutes()) % 60;
  const seconds = Math.floor(diff.asSeconds()) % 60;

  const diffString = `
  ${fn_(days)}<b>:</b>${fn_(hours)}<b>:</b>${fn_(minutes)}<b>:</b>${fn_(
    seconds
  )}`;
  return diffString;
};

export const getPanelMode = () => {
  // eslint-disable-next-line no-undef
  return process.env?.REACT_APP_MODE;
};

const getSdkUrl = () => {
  const url = window.location?.href;

  // is Test Or Dev Mode
  if (url?.includes("stage-") || url?.includes("localhost")) {
    // eslint-disable-next-line no-undef
    return process.env.REACT_APP_SAMAR_URL;
  } else {
    // eslint-disable-next-line no-undef
    return process.env.REACT_APP_SAMAR_URL_PRODUCTION + "/web/";
  }
};

export const codeBase = `<script type="text/javascript" async src="${getSdkUrl()}samar-sdk.js"></script>`;

/**
 * NEW FUNCTION FOR ON FINISH METHOD
 * @param {*} func
 * @param {*} dispatch
 * @param {*} body
 */
export const onFinishHelper = async (func, dispatch, body, options) => {
  const result = await dispatch(func(body));
  if (result.payload?.success) {
    message.success({
      content: t(options?.successMessage || "general.saveSuccessFully"),
    });
    options?.successCallback();
  } else {
    message.error({
      content: result.payload?.Message || t("general.unHandleErrorMessage"),
    });
  }
};

// for InTrack Event
export const getMediaName = (mediaId, mediaList) => {
  const d = mediaList.find((item) => item.media_id + "" === mediaId + "");
  return d?.name;
};
export const getWebsiteName = (webId, websiteList) => {
  const d = websiteList.find((item) => item.web_store_id + "" === webId + "");
  return d?.name;
};

export const defaultRules = (t) => {
  const rules = [
    {
      required: true,
      message: t("required"),
    },
  ];

  return rules;
};

export const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
